import { useEffect, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useAppContext } from '../../../AppContext'
import { userService } from '../../../shared/service'
import classNames from 'classnames'

const { REACT_APP_PREFIX } = process.env

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const getError = (
  key: string,
  value: string | boolean,
  otherValue: string | null = null
): Error | null => {
  if (!value) {
    return { field: key, error: 'required' }
  }
  switch (key) {
    case 'email':
      if (!validateEmail(value as string)) {
        return { field: key, error: 'email' }
      }
      break
    case 'repeatPassword':
      if (value !== otherValue) {
        return { field: key, error: 'password' }
      }
  }
  return null
}

type UserData = {
  firstName: string
  lastName: string
  email: string
  password: string
  repeatPassword: string
  consent: boolean
}

type Error = {
  field: string
  error: string
}

function Registration() {
  const { accessToken, setAccessToken } = useAppContext()
  const history = useHistory()
  const [userData, setUserData] = useState<UserData>({
    consent: false,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: ''
  })
  const [userErrors, setUserErrors] = useState<Error[]>([])
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    checkErrors()
  })

  const checkErrors = () => {
    Object.entries(userData).forEach(([key, value]) => {
      let error = getError(key, value, userData.password)
      if (error) {
        let errorCode = error.error
        setUserErrors((prevErrors) => {
          if (prevErrors.find((err) => err.field === key && err.error === errorCode)) {
            return prevErrors
          }
          let filteredErrors = userErrors.filter((err) => err.field !== key)
          return [...filteredErrors, { field: key, error: errorCode }]
        })
      } else {
        let errIndex = userErrors.findIndex((err) => err.field === key)
        if (errIndex > -1) {
          setUserErrors((prevErrors) => {
            prevErrors.splice(errIndex, 1)
            return [...prevErrors]
          })
        }
      }
    })
  }

  const changeData = (data: string | boolean, key: string) => {
    setUserData((prevData) => {
      return { ...prevData, [key]: data }
    })
  }

  const showError = (
    field: string,
    error: string | string[],
    showBeforeSubmit: boolean = false
  ): boolean => {
    if (typeof error === 'string') {
      error = [error]
    }
    return (
      (submitted || showBeforeSubmit) &&
      userErrors.findIndex((e) => e.field === field && error.includes(e.error)) > -1
    )
  }

  const registerUser = async () => {
    setSubmitted(true)

    if (userErrors.length > 0) {
      return
    }

    setLoading(true)

    let { success, data } = await userService.register(userData)

    if (success) {
      ;({ success, data } = await userService.login(userData.email, userData.password))

      if (success) {
        setAccessToken(() => {
          localStorage.setItem(REACT_APP_PREFIX + '_storage', 'local')
          return data
        })

        setLoading(false)
        history.push('/account')
        return
      }
    }

    setLoading(false)
    setError(data.error)
  }

  if (accessToken) {
    return <Redirect to='/account' />
  }

  return (
    <div className='block max-w-md mx-auto p-5'>
      <h1 className='page-title'>Registration</h1>
      <form className='space-y-6'>
        <label className='block space-y-2'>
          <span>Your name</span>
          <input
            type='text'
            placeholder='First name'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': showError('firstName', 'required')
            })}
            onChange={(e) => changeData(e.target.value, 'firstName')}
          />
          {showError('firstName', 'required') && (
            <small className='error-text'>This field is required</small>
          )}
          <input
            type='text'
            placeholder='Last name'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': showError('lastName', 'required')
            })}
            onChange={(e) => changeData(e.target.value, 'lastName')}
          />
          {showError('lastName', 'required') && (
            <small className='error-text'>This field is required</small>
          )}
        </label>

        <label className='block space-y-2'>
          <span>E-mail address</span>
          <input
            type='email'
            placeholder='Enter e-mail'
            autoComplete='username'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': showError('email', ['required', 'email'])
            })}
            onChange={(e) => changeData(e.target.value, 'email')}
          />
          {showError('email', 'required') && (
            <small className='error-text'>This field is required</small>
          )}
          {showError('email', 'email', true) && (
            <small className='error-text'>Invalid e-mail address format</small>
          )}
        </label>

        <label className='block space-y-2'>
          <span>Password</span>
          <input
            type='password'
            placeholder='Enter password'
            autoComplete='new-password'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': showError('password', 'required')
            })}
            onChange={(e) => changeData(e.target.value, 'password')}
          />
          {showError('password', 'required') && (
            <small className='error-text'>This field is required</small>
          )}
          <input
            type='password'
            placeholder='Repeat your password'
            autoComplete='new-password'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': showError('repeatPassword', ['required', 'password'])
            })}
            onChange={(e) => changeData(e.target.value, 'repeatPassword')}
          />
          {showError('repeatPassword', 'required') && (
            <small className='error-text'>This field is required</small>
          )}
          {showError('repeatPassword', 'password', true) && (
            <small className='error-text'>Passwords do not match</small>
          )}
        </label>

        <label className='block space-y-2'>
          <input
            id='consent'
            type='checkbox'
            placeholder='Consent'
            className={classNames('rounded input-checkbox', {
              'focus-ring-error': showError('consent', 'required')
            })}
            onChange={(e) => changeData(e.target.checked, 'consent')}
          />
          <span className='ml-2 align-middle'>
            I accept the&nbsp;
            <Link to='/terms' target='_blank'>
              <span className='primary-link'>Terms of Use</span>
            </Link>
            &nbsp;&&nbsp;
            <Link to='/privacy-policy' target='_blank'>
              <span className='primary-link'>Privacy Policy</span>
            </Link>
            .
          </span>
          {showError('consent', 'required') && (
            <small className='block error-text'>Please accept this</small>
          )}
          {submitted && error && <small className='error-text'>{error}</small>}
        </label>

        <div className='sm:flex block items-center justify-between'>
          <button
            type='button'
            className='primary-button w-full sm:w-48 order-last'
            disabled={loading}
            onClick={registerUser}
          >
            Register
          </button>
          <Link to={`/account/login`} className='invisible sm:visible'>
            <span className='primary-link mx-auto'>Sign in</span>
          </Link>
        </div>

        <div className='sm:invisible text-center'>
          Already have an account?{' '}
          <Link to={`/account/login`} className='primary-link'>
            Sign in
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Registration
