import { useWeb3Context, web3 } from '../../Web3Context'
import { formatNumber, rarityName } from '../../shared/utils'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import classNames from 'classnames'
import './TokenCard.css'
import { Token } from '../../shared/types'
import useContract from '../../shared/hooks/use-contract'
import { tokenService } from '../../shared/service'
import DialogOverlay from '../DialogOverlay/DialogOverlay'
import pendingTransactionReducer from '../../shared/reducers/pending-transaction-reducer'

const { REACT_APP_API } = process.env

type TokenCardProps = {
  token: Token
  hideOwner?: boolean
  tokenAction?: (event: string) => void
}

function TokenCard(props: TokenCardProps) {
  const { wallet, contract } = useWeb3Context()
  const { buyToken, makeTokenOffer } = useContract()
  const [loading, setLoading] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [refreshCount, setRefreshCount] = useState<number>(0)
  const imgRef = useRef<HTMLImageElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)
  const [buyState, dispatchBuy] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const [offerState, dispatchOffer] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })

  useEffect(() => {
    if (loading) {
      cardRef.current?.classList.add('animate-pulse')
    } else {
      cardRef.current?.classList.remove('animate-pulse')
    }
  }, [loading])

  useEffect(() => {
    if (!props.token.updating) {
      setUpdating(false)
      return
    }

    setUpdating(true)
    const interval = setInterval(async () => {
      let { data } = await tokenService.getToken(props.token.id)

      if (data && !data.updating) {
        props.token.updating = false
        setUpdating(false)
        clearInterval(interval)
      }
    }, 3000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [props, updating])

  const isState = useCallback(
    (testState: string, skipOwner: boolean = false): boolean => {
      let state = 'noSale'

      if (
        props.token.address &&
        wallet &&
        wallet.account &&
        props.token.address.toLowerCase() === wallet.account.toLowerCase() &&
        !skipOwner
      ) {
        state = 'owner'
      } else if (props.token.value && +props.token.value > 0) {
        state = 'buy'
      } else if (props.token.value && +props.token.value === 0) {
        state = 'offer'
      }

      return testState === state
    },
    [props, wallet]
  )

  const reloadImage = () => {
    if (!loading) {
      setLoading(true)
    }
    if (refreshCount > 120) {
      return
    }

    setTimeout(() => {
      if (!imgRef.current) {
        return
      }
      let currentSrc = imgRef.current.src.split('?')[0]
      imgRef.current.src = currentSrc + '?t=' + new Date().getTime()
      setRefreshCount((prevCount) => prevCount + 1)
    }, 2000)
  }

  const formatValue = (): string => {
    return formatNumber(+web3.utils.fromWei(props.token.value || '0', 'ether'))
  }

  const buy = async () => {
    if (props.token.updating) {
      alert('Price is being updated, please try again later')
      return
    }
    const currentPrice = await contract.methods.getTokenPrice(props.token.id).call()
    let { error } = await buyToken(props.token.id, currentPrice, (err, txHash) => {
      if (err) {
        return dispatchBuy({
          type: 'error',
          payload: { error: typeof err === 'string' ? err : err.message }
        })
      }
      dispatchBuy({ type: 'start', payload: { hash: txHash } })
    })

    if (error) {
      return dispatchBuy({ type: 'error', payload: { error } })
    }

    props.token.address = wallet.account
    props.token.value = '0'

    dispatchBuy({
      type: 'success',
      payload: {
        message: `<span>Transaction completed, you have successfully acquired token: </span><br><a class="primary-link font-bold block break-all" href="/explore/${props.token.id}">${window.location.host}/explore/${props.token.id}</a>`
      }
    })

    props.tokenAction?.('buy')
  }

  const sendOffer = async () => {
    if (props.token.updating) {
      alert('Price is being updated, please try again later')
      return
    }
    const highestOffer = web3.utils.fromWei(props.token.highestOfferValue || '0')
    const value = prompt(
      `Enter offer value in ETH (highest offer: ${
        +props.token.highestOfferValue > 0 ? highestOffer + ' ETH' : 'none'
      })`
    )
    const parsedValue = parseFloat((value as string) || '0')
    if (!isNaN(parsedValue) && parsedValue > 0) {
      const offerValue = web3.utils.toWei(parsedValue.toString(), 'ether')
      let { error } = await makeTokenOffer(props.token.id, offerValue, (err, txHash) => {
        if (err) {
          return dispatchOffer({
            type: 'error',
            payload: { error: typeof err === 'string' ? err : err.message }
          })
        }
        dispatchOffer({ type: 'start', payload: { hash: txHash } })
      })

      if (error) {
        return dispatchOffer({ type: 'error', payload: { error } })
      }

      let highestOfferValue = '0'
      let highestOfferAddress = ''
      let offers = await contract.methods.getTokenOffers(props.token.id).call()
      for (let offer of offers) {
        if (web3.utils.toBN(highestOfferValue).lt(web3.utils.toBN(offer[1]))) {
          highestOfferAddress = offer[0]
          highestOfferValue = offer[1]
        }
      }
      props.token.highestOfferValue = highestOfferValue
      props.token.highestOfferAddress = highestOfferAddress

      dispatchOffer({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully submitted offer of ${parsedValue} ETH for buying token: </span><br><a class="primary-link font-bold block break-all" href="/explore/${props.token.id}">${window.location.host}/explore/${props.token.id}</a>`
        }
      })

      props.tokenAction?.('offer')
    }
  }

  return (
    <>
      <div
        ref={cardRef}
        className={classNames('token-container', {
          border: props.token.rarity > 1,
          [rarityName(props.token.rarity).toLowerCase()]: true
        })}
      >
        <Link to={`/explore/${props.token.id}`}>
          <p className='p-3'>#{props.token.id}</p>
          {updating && (
            <span
              className='absolute top-3 right-2 animate-spin'
              title='New price is being synchronized'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                />
              </svg>
            </span>
          )}
          <div className='flex-grow'>
            <img
              ref={imgRef}
              src={`${REACT_APP_API}/images/${props.token.imageName}`}
              alt=''
              className='h-44 object-cover w-full'
              onError={reloadImage}
              onLoad={() => setLoading(false)}
            />
          </div>
        </Link>

        {isState('buy') && (
          <button className='primary-button mb-3 mx-3 whitespace-nowrap' onClick={buy}>
            Buy {formatValue()} ETH
          </button>
        )}

        {isState('offer') && (
          <button className='primary-button mb-3 mx-3' onClick={sendOffer}>
            Send offer
          </button>
        )}

        {isState('noSale') && (
          <Link to={`/explore/${props.token.id}`}>
            <span className='block mb-5 text-gray-600 dark:text-gray-200'>Not for sale</span>
          </Link>
        )}

        {isState('owner') && !props.hideOwner && (
          <Link to={`/explore/${props.token.id}`}>
            <span className='block mt-2 text-green-600 dark:text-green-400'>Owned</span>
          </Link>
        )}

        {isState('owner') && (
          <Link to={`/explore/${props.token.id}`}>
            <span className='block mb-5 text-gray-600 dark:text-gray-200'>
              {isState('buy', true) && (
                <span className=''>
                  Selling <span className='font-bold'>{formatValue()}</span> ETH
                </span>
              )}
              {isState('offer', true) && <span className=''>Open to offers</span>}
              {isState('noSale', true) && <span className=''>Not for sale</span>}
            </span>
          </Link>
        )}
      </div>

      <DialogOverlay
        message={buyState.message}
        loading={buyState.loading}
        error={buyState.error}
        opened={buyState.started}
        onClose={() => dispatchBuy({ type: 'close' })}
      />

      <DialogOverlay
        message={offerState.message}
        loading={offerState.loading}
        error={offerState.error}
        opened={offerState.started}
        onClose={() => dispatchOffer({ type: 'close' })}
      />
    </>
  )
}

export default TokenCard
