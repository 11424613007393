import { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useWeb3Context, web3 } from '../../../Web3Context'
import useContract from '../../../shared/hooks/use-contract'
import { tokenService, web3Service } from '../../../shared/service'
import { formatNumber } from '../../../shared/utils'
import DialogOverlay from '../../../components/DialogOverlay/DialogOverlay'
import useApi from '../../../shared/hooks/use-api'
import { TokensApiData } from '../../../shared/types'
import pendingTransactionReducer from '../../../shared/reducers/pending-transaction-reducer'

function AdminPanel() {
  const history = useHistory()
  const { wallet, contract } = useWeb3Context()
  const {
    contractOwner,
    totalSupply,
    withdrawContractFunds,
    bulkMintTokens,
    transferToken,
    burnToken
  } = useContract()
  const [contractBalance, setContractBalance] = useState<string>('0')
  const [withdrawableFunds, setWithdrawableFunds] = useState<string>('0')
  const [transferTokenId, setTransferTokenId] = useState<string>('')
  const [burnTokenId, setBurnTokenId] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const { data: response } = useApi<TokensApiData>(`/tokens/query?address=${wallet.account}`)
  const [tokenIds, setTokenIds] = useState<string[]>([])
  const [withdrawState, dispatchWithdraw] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const [bulkMintState, dispatchBulkMint] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const [transferState, dispatchTransfer] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const [burnState, dispatchBurn] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })

  useEffect(() => {
    if (wallet && contractOwner) {
      if (wallet.account !== contractOwner) {
        history.push('/account')
      }
    }
  }, [contractOwner, wallet, history])

  useEffect(() => {
    if (!response) {
      return
    }
    setTokenIds(response.data.map((t) => t.id))
  }, [response])

  useEffect(() => {
    if (!contract) {
      setContractBalance('0')
      return
    }

    ;(async () => {
      const balance = await web3Service.getAccountBalance(contract?.options.address)
      setContractBalance(balance)

      const funds = await contract.methods.fundsForWithdrawal().call()
      setWithdrawableFunds(web3.utils.fromWei(funds))
    })()
  }, [contract])

  const withdrawFunds = async () => {
    if (wallet && contract) {
      const amount = prompt(
        `How much would you like to withdraw in ETH? (max: ${withdrawableFunds} ETH)`
      )
      if (amount === null) {
        return
      }
      if (!amount || +amount > +withdrawableFunds || +amount <= 0) {
        alert('Invalid amount for withdrawal specified')
        return
      }
      dispatchWithdraw({ type: 'init' })

      let { error } = await withdrawContractFunds(web3.utils.toWei(amount), (err, txHash) => {
        if (err) {
          return dispatchWithdraw({
            type: 'error',
            payload: { error: typeof err === 'string' ? err : err.message }
          })
        }
        dispatchWithdraw({ type: 'start', payload: { hash: txHash } })
      })

      if (!error) {
        const balance = await web3Service.getAccountBalance(contract?.options.address)
        setContractBalance(balance)

        setWithdrawableFunds((prevWithdrawableFunds) =>
          (parseFloat(prevWithdrawableFunds) - parseFloat(amount)).toString()
        )
      } else {
        return dispatchWithdraw({ type: 'error', payload: { error } })
      }

      dispatchWithdraw({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully withdrawn pending balance of <b>${amount}</b> ETH to your wallet</span>`
        }
      })
    } else {
      console.error('Wallet and contract are not initialized')
    }
  }

  const bulkMint = async () => {
    if (wallet && contract) {
      const count = prompt('How many tokens would you like to mint?')
      if (count === null) {
        return
      }
      if (!count || +count <= 0) {
        alert('Invalid number for minting tokens specified')
        return
      }
      dispatchBulkMint({ type: 'init' })

      const ids: string[] = []
      for (let i = 0; i < +count; i++) {
        const { data: token } = await tokenService.generateId()
        ids.push(token.id)
      }

      let { error } = await bulkMintTokens(ids, (err, txHash) => {
        if (err) {
          return dispatchBulkMint({
            type: 'error',
            payload: { error: typeof err === 'string' ? err : err.message }
          })
        }
        dispatchBulkMint({ type: 'start', payload: { hash: txHash } })
      })

      if (error) {
        return dispatchBulkMint({ type: 'error', payload: { error } })
      }

      setTokenIds((prevTokenIds) => {
        return [...prevTokenIds, ...ids]
      })

      dispatchBulkMint({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully bulk minted <b>${count}</b> tokens</span>`
        }
      })
    } else {
      console.error('Wallet and contract are not initialized')
    }
  }

  const isTransferDataValid = () => {
    return (
      transferTokenId &&
      tokenIds.find((tid) => tid === transferTokenId) &&
      address &&
      address.length === 42
    )
  }

  const transferTokenToAddress = async () => {
    if (wallet && contract) {
      dispatchTransfer({ type: 'init' })

      let { error } = await transferToken(
        wallet.account,
        address,
        transferTokenId,
        (err, txHash) => {
          if (err) {
            return dispatchTransfer({
              type: 'error',
              payload: { error: typeof err === 'string' ? err : err.message }
            })
          }
          dispatchTransfer({ type: 'start', payload: { hash: txHash } })
        }
      )

      if (error) {
        return dispatchTransfer({ type: 'error', payload: { error } })
      }

      setTransferTokenId('')
      setAddress('')

      setTokenIds((prevTokenIds) => {
        return [...prevTokenIds.filter((tid) => tid !== transferTokenId)]
      })

      dispatchTransfer({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully transfered token ID <b>${transferTokenId}</b> to account <b>${address}</b></span>`
        }
      })
    } else {
      console.error('Wallet and contract are not initialized')
    }
  }

  const isBurnDataValid = () => {
    return burnTokenId && tokenIds.find((tid) => tid === burnTokenId)
  }

  const burnTokenWithId = async () => {
    if (wallet && contract) {
      dispatchBurn({ type: 'init' })

      let { error } = await burnToken(burnTokenId, (err, txHash) => {
        if (err) {
          return dispatchBurn({
            type: 'error',
            payload: { error: typeof err === 'string' ? err : err.message }
          })
        }
        dispatchBurn({ type: 'start', payload: { hash: txHash } })
      })

      if (error) {
        return dispatchBurn({ type: 'error', payload: { error } })
      }

      setBurnTokenId('')

      setTokenIds((prevTokenIds) => {
        return [...prevTokenIds.filter((tid) => tid !== burnTokenId)]
      })

      dispatchBurn({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully burned token ID <b>${burnTokenId}</b></span>`
        }
      })
    } else {
      console.error('Wallet and contract are not initialized')
    }
  }

  return (
    <div className='block max-w-2xl 2xl:max-w-4xl mx-auto'>
      <h1 className='page-title text-center px-5 pt-5'>Admin panel</h1>
      <div className='flex flex-col space-y-4 w-full px-5 mt-5'>
        <h2>Contract management</h2>
        <span className='break-all sm:break-normal'>
          <b className='block sm:inline'>Address: </b>
          <span className='text-sm sm:text-base'>{contract?.options.address.toLowerCase()}</span>
        </span>

        <span className='break-all sm:break-normal'>
          <b className='block sm:inline'>Total balance: </b>
          <span className='text-sm sm:text-base'>{formatNumber(+contractBalance)} ETH</span>
        </span>

        <span className='break-all sm:break-normal'>
          <b className='block sm:inline'>Withdrawable balance: </b>
          <span className='text-sm sm:text-base'>{formatNumber(+withdrawableFunds)} ETH</span>
        </span>

        <button
          disabled={+withdrawableFunds === 0}
          className='primary-button w-full sm:w-80'
          onClick={withdrawFunds}
        >
          Withdraw funds
        </button>

        <h2 className='pt-10'>Token management</h2>
        <span className='break-all sm:break-normal'>
          <b className='block sm:inline'>Minted tokens: </b>
          <span className='text-sm sm:text-base'>{totalSupply}</span>
        </span>

        <button className='primary-button w-full sm:w-80' onClick={bulkMint}>
          Bulk mint tokens
        </button>

        <div className='w-full sm:w-80 pt-4 block space-y-2'>
          <label htmlFor='tokenId'>Transfer token: </label>
          <input
            id='tokenId'
            name='tokenId'
            type='text'
            className='input-field focus-ring'
            list='token-list'
            placeholder='Enter or select token ID'
            value={transferTokenId}
            onChange={(e) => setTransferTokenId(e.target.value)}
          />
        </div>
        <div className='w-full sm:w-80 block space-y-2'>
          <label htmlFor='tokenId'>To address: </label>
          <input
            type='text'
            className='input-field focus-ring text-xs h-11'
            minLength={42}
            maxLength={42}
            placeholder='Enter address (e.g. 0xD8dff4...)'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <button
          disabled={!isTransferDataValid()}
          className='primary-button w-full sm:w-80'
          onClick={transferTokenToAddress}
        >
          Transfer
        </button>

        <div className='w-full sm:w-80 pt-4 block space-y-2'>
          <label htmlFor='burnTokenId'>Burn token: </label>
          <input
            id='burnTokenId'
            name='burnTokenId'
            type='text'
            className='input-field focus-ring'
            list='token-list'
            placeholder='Enter or select token ID'
            value={burnTokenId}
            onChange={(e) => setBurnTokenId(e.target.value)}
          />
        </div>
        <button
          disabled={!isBurnDataValid()}
          className='primary-button w-full sm:w-80'
          onClick={burnTokenWithId}
        >
          Burn
        </button>

        <datalist id='token-list'>
          {tokenIds.map((tokenId) => (
            <option key={tokenId}>{tokenId}</option>
          ))}
        </datalist>
      </div>

      <DialogOverlay
        message={withdrawState.message}
        loading={withdrawState.loading}
        error={withdrawState.error}
        opened={withdrawState.started}
        onClose={() => dispatchWithdraw({ type: 'close' })}
      />

      <DialogOverlay
        message={bulkMintState.message}
        loading={bulkMintState.loading}
        error={bulkMintState.error}
        opened={bulkMintState.started}
        onClose={() => dispatchBulkMint({ type: 'close' })}
      />

      <DialogOverlay
        message={transferState.message}
        loading={transferState.loading}
        error={transferState.error}
        opened={transferState.started}
        onClose={() => dispatchTransfer({ type: 'close' })}
      />

      <DialogOverlay
        message={burnState.message}
        loading={burnState.loading}
        error={burnState.error}
        opened={burnState.started}
        onClose={() => dispatchBurn({ type: 'close' })}
      />
    </div>
  )
}

export default AdminPanel
