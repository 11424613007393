import { Transaction } from '../../shared/types'
import { web3 } from '../../Web3Context'
import useApi from '../../shared/hooks/use-api'
import { useMemo, useState } from 'react'
import Pagination from '../Pagination/Pagination'
import { formatNumber } from '../../shared/utils'
import { Link } from 'react-router-dom'

const { REACT_APP_ETHERSCAN } = process.env

type TransactionTableProps = {
  identifier: string
  page?: number
  size?: number
  clickableId?: boolean
}

type TransactionsApiData = {
  data: Transaction[]
  resultCount: number
  totalCount: number
}

function TransactionTable(props: TransactionTableProps) {
  const size = useMemo(() => {
    return props.size ? props.size : 6
  }, [props])
  const [page, setPage] = useState(props.page ? props.page : 1)
  const { data: response, loading } = useApi<TransactionsApiData>(
    `/transactions/query?address=${props.identifier}&page=${page}&size=${size}`
  )

  const getTransferType = (tx: Transaction): string => {
    if (tx.from === '0x0000000000000000000000000000000000000000') {
      return 'Mint'
    }

    if (tx.to === '0x0000000000000000000000000000000000000000') {
      return 'Burn'
    }

    return 'Transfer'
  }

  const trn = (str: string) => {
    if (str.length === 0) {
      return ''
    }
    return str.substr(0, 8) + '...'
  }

  return (
    <>
      <div className='overflow-auto' style={{ opacity: loading ? '0.7' : '1' }}>
        <table className='table-border table-auto text-left w-full' style={{ minWidth: '647px' }}>
          <thead>
            <tr>
              <th className='p-2'>Type</th>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
              <th>Txn</th>
              <th>Token id</th>
            </tr>
          </thead>
          <tbody className='min-w-2xl'>
            {response &&
              response.data &&
              response.data.map((tx: Transaction) => (
                <tr
                  key={tx.id}
                  className='table-border hover:bg-black hover:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-10'
                >
                  <td className='p-2'>{tx.type === 'Transfer' ? getTransferType(tx) : tx.type}</td>
                  <td title={tx.from}>{trn(tx.from)}</td>
                  <td title={tx.to}>{trn(tx.to)}</td>
                  <td>{formatNumber(+web3.utils.fromWei(tx.value, 'ether'))} Ξ</td>
                  <td title={tx.hash}><a className='primary-link' href={`${REACT_APP_ETHERSCAN}/tx/${tx.hash}`} target='_blank' rel='noreferrer'>{trn(tx.hash)}</a></td>
                  <td>{props?.clickableId && getTransferType(tx) !== 'Burn' ? <Link className='primary-link' to={`/explore/${tx.tokenId}`}>{tx.tokenId}</Link> : tx.tokenId}</td>
                </tr>
              ))}
          </tbody>
        </table>

        {response && response.resultCount === 0 && (
          <div className='text-center table-border pt-4 pb-4'>There are no results</div>
        )}
      </div>
      <div className='mt-0 pb-10'>
        {response && response.data && (
          <Pagination
            page={page}
            size={size}
            totalCount={response.totalCount}
            pageChanged={setPage}
          />
        )}
      </div>
    </>
  )
}

export default TransactionTable
