import { Transaction } from '../types'

const { REACT_APP_API } = process.env

async function getTransaction(hash: string): Promise<{ success: boolean; data: Transaction }> {
  let resp = await fetch(`${REACT_APP_API}/transactions/${hash}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  })

  const data = await resp.json()
  return { success: resp.ok, data }
}

export const transactionService = {
  getTransaction
}
