import { Link } from 'react-router-dom'
import { getNetworkName } from '../../shared/utils'
import TokenList from '../../components/TokenList/TokenList'

const { REACT_APP_NETWORK_ID } = process.env

function Home() {
  return (
    <div className='min-w-full'>
      <article className='prose prose-md sm:prose-lg mx-auto p-5'>
        <h1 className='text-center'>Mega NFT project</h1>
        <p className='text-justify sm:text-left'>
          Unlimited amount of randomly generated tokens which are stored on
          Ethereum ({getNetworkName(REACT_APP_NETWORK_ID || '0', true)}) blockchain.
          Mega tokens are represented as an image generated by <a className='primary-link' target='_blank'
                                                                  rel='noreferrer'
                                                                  href='https://openai.com/product/dall-e-2'>OpenAI
          DALL-E 2</a> image generator using 3 random words as prompt. Also, every token has one of the following
          random rarity levels:
        </p>
        <ul>
          <li className='common'>Common</li>
          <li className='rare'>Rare</li>
          <li className='epic'>Epic</li>
          <li className='legendary'>Legendary</li>
        </ul>

        <h2 className='text-center'>How do I get token?</h2>
        <p className='text-justify sm:text-left'>
          You can acqiure tokens by{' '}
          <Link className='primary-link' to='/claim'>
            Claiming new ones
          </Link>{' '}
          or you can{' '}
          <Link className='primary-link' to='/explore'>
            buy them
          </Link>{' '}
          from other users, but only if they are offered for sale or open to offers.
        </p>
      </article>

      <h2 className='mt-12 pl-5'>Recently minted tokens</h2>
      <div className='flex flex-col space-y-4'>
        <TokenList address='' hideFilters={true} maxCount={15} />
      </div>
    </div>
  )
}

export default Home
