import useContract from '../../shared/hooks/use-contract'
import { useEffect, useReducer, useRef, useState } from 'react'
import DialogOverlay from '../../components/DialogOverlay/DialogOverlay'
import HowToClaim from './HowToClaim'
import pendingTransactionReducer from '../../shared/reducers/pending-transaction-reducer'
import { web3 } from '../../Web3Context'
import { formatNumber } from '../../shared/utils'

function Claim() {
  const counterRef = useRef<HTMLElement>(null)
  const { totalSupply, mintValue, mintToken } = useContract()
  const [lastSupply, setLastSupply] = useState(null)
  const [state, dispatch] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })

  const claimToken = async () => {
    dispatch({ type: 'init' })

    let { error, tx } = await mintToken((err, txHash) => {
      if (err) {
        return dispatch({
          type: 'error',
          payload: { error: typeof err === 'string' ? err : err.message }
        })
      }
      dispatch({ type: 'start', payload: { hash: txHash } })
    })

    if (error) {
      return dispatch({ type: 'error', payload: { error } })
    }

    // Give some time so backend can process transaction and create new token
    setTimeout(() => {
      const tokenId = tx.events.Transfer.returnValues._tokenId
      dispatch({
        type: 'success',
        payload: {
          message: `<span>Your token has been successfully minted! You can access it on following page:</span><br><a class="primary-link font-bold block break-all" href="/explore/${tokenId}">${window.location.host}/explore/${tokenId}</a>`
        }
      })
    }, 3000)
  }

  useEffect(() => {
    if (!lastSupply) {
      const supply = totalSupply
      return () => {
        setLastSupply(supply)
      }
    }
    const counter = counterRef.current
    counter?.classList.remove('text-yellow-600', 'dark:text-yellow-600')
    counter?.classList.add('text-yellow-500', 'dark:text-yellow-500')
    const sub = setTimeout(() => {
      counter?.classList.remove('text-yellow-500', 'dark:text-yellow-500')
      counter?.classList.add('text-yellow-600', 'dark:text-yellow-600')
    }, 400)
    return () => {
      clearTimeout(sub)
    }
  }, [totalSupply, lastSupply])

  return (
    <div id='claim' className='page-container'>
      <div className='prose prose-md sm:prose-lg mx-auto text-center space-y-10'>
        <h1>Claim your token</h1>
        <p>
          Use this unique opportunity to claim brand new Mega NFT token for only{' '}
          <span className='text-yellow-600 dark:text-yellow-600 font-bold'>
            {formatNumber(+web3.utils.fromWei(mintValue))} ETH
          </span>
          . After claiming the token you can sell it on any NFT marketplace.
        </p>
        <div>
          <span
            ref={counterRef}
            className='block text-6xl text-yellow-600 dark:text-yellow-600 font-bold transition-colors ease-in-out duration-400'
          >
            {totalSupply !== null ? (
              totalSupply
            ) : (
              <div className='animate-bounce dark:text-yellow-600'>...</div>
            )}
          </span>
          <span className='block text-2xl mt-2'>Tokens claimed so far</span>
        </div>
        <div className='text-center'>
          <button className='primary-button font-bold w-full sm:w-72' onClick={claimToken}>
            Claim a random token
          </button>
        </div>
      </div>

      {state.error && (
        <div className='text-center mt-2'>
          <small className='error-text'>{state.message}</small>
        </div>
      )}

      <HowToClaim />

      <DialogOverlay
        message={state.message}
        loading={state.loading}
        error={state.error}
        opened={state.started}
        onClose={() => dispatch({ type: 'close' })}
      />
    </div>
  )
}

export default Claim
