import { createContext, useContext, useEffect, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import useWallet from './shared/hooks/use-wallet'
import { ContractAbi, Wallet } from './shared/types'
import Web3 from 'web3'
import useApi from './shared/hooks/use-api'

const { REACT_APP_NETWORK_ID, REACT_APP_DEFAULT_NODE } = process.env

type Web3ContextData = {
  wallet: Wallet | any
  setWallet: any
  contract: Contract | any
}

const Web3Context = createContext<Web3ContextData>({
  wallet: null,
  setWallet: undefined,
  contract: null
})

const Web3ContextProvider = ({ children }: any) => {
  const [wallet, setWallet] = useWallet()
  const [contract, setContract] = useState<Contract | null>()
  const { data: contractInterface } = useApi<ContractAbi>('/contract?fields=abi,bytecode,networks')

  useEffect(() => {
    if (!contractInterface) {
      return
    }

    const networkId =
      wallet && wallet.chainId
        ? parseInt(wallet.chainId).toString()
        : (REACT_APP_NETWORK_ID as string)

    if (!(networkId in contractInterface.networks)) {
      return setContract(null)
    }

    const contractAddress = contractInterface.networks[networkId].address
    const instance = new web3.eth.Contract(contractInterface.abi, contractAddress, {
      from: contractAddress,
      data: contractInterface.bytecode
    })

    setContract(instance)
  }, [contractInterface, wallet])

  const web3Context = {
    wallet,
    setWallet,
    contract
  }

  return <Web3Context.Provider value={web3Context}>{children}</Web3Context.Provider>
}

const web3 = new Web3(Web3.givenProvider || REACT_APP_DEFAULT_NODE)

const useWeb3Context = () => useContext(Web3Context)

export { Web3ContextProvider, useWeb3Context, web3 }
