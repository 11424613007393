import { useEffect, useReducer } from 'react'
import { useAppContext } from '../../AppContext'

const { REACT_APP_API } = process.env

type ApiState<T> = {
  loading: boolean
  error: string | null
  data: T | null
  abort: () => void
}

type ApiAction<T> = {
  type: 'start' | 'success' | 'error'
  payload?: {
    error?: string
    data?: T | null
    abort?: () => void
  }
}

const createApiReducer = <T,>() => (state: ApiState<T>, action: ApiAction<T>): ApiState<T> => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        loading: true,
        abort: action.payload?.abort || (() => {})
      }
    case 'success':
      return { ...state, loading: false, data: (action.payload?.data || {}) as T }
    case 'error':
      return { ...state, loading: false, error: action.payload?.error || '' }
    default:
      return state
  }
}

function useApi<T>(url: string, method: string = 'GET', body: any = {}) {
  const { accessToken } = useAppContext()
  let [state, dispatch] = useReducer(createApiReducer<T>(), {
    loading: false,
    error: null,
    data: null,
    abort: () => {}
  })

  const bodyString = JSON.stringify(body)

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      dispatch({ type: 'start', payload: { abort: controller.abort } })

      try {
        let reqParams: RequestInit = {
          method: method,
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
        if (bodyString !== '{}' && ['POST', 'PUT'].indexOf(method.toUpperCase()) > -1) {
          reqParams.body = bodyString
        }
        let response = await fetch(`${REACT_APP_API}${url}`, reqParams)
        if (response.ok) {
          let data = (await response.json()) as T
          dispatch({ type: 'success', payload: { data: data} })
        } else {
          let data = (await response.json()) as any
          dispatch({ type: 'error', payload: { error: data.error } })
        }
      } catch (error) {
        dispatch({ type: 'error', payload: { error: error } })
      }
    })()

    return () => {
      controller.abort()
    }
  }, [url, method, accessToken, bodyString])

  return state
}

export default useApi
