import { useEffect, useReducer, useState } from 'react'
import { useWeb3Context, web3 } from '../../Web3Context'
import { formatNumber } from '../../shared/utils'
import useContract from '../../shared/hooks/use-contract'
import DialogOverlay from '../DialogOverlay/DialogOverlay'
import { Token } from '../../shared/types'
import pendingTransactionReducer from '../../shared/reducers/pending-transaction-reducer'

type OfferListProps = {
  token: Token
  account?: string
  offerAction?: (event: string) => void
}

type Offer = {
  address: string
  value: string
}

function OfferList(props: OfferListProps) {
  const { contract } = useWeb3Context()
  const { revokeTokenOffer, acceptTokenOffer } = useContract()
  const [offers, setOffers] = useState<Offer[]>([])
  const [revokeState, dispatchRevoke] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const [acceptState, dispatchAccept] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })

  useEffect(() => {
    if (!contract) {
      return
    }

    ;(async () => {
      let tokenOffers
      try {
        tokenOffers = await contract.methods.getTokenOffers(props.token.id).call()
      } catch (e) {
        return
      }
      const offerList = []
      for (let offer of tokenOffers) {
        if (offerList.length >= 30) {
          break
        }
        offerList.push({ address: offer[0].toLowerCase(), value: offer[1] })
      }
      if (offerList.length > 0) {
        offerList.sort((first, second) =>
          web3.utils.toBN(first.value).sub(web3.utils.toBN(second.value)).isNeg() ? 1 : -1
        )
        setOffers(offerList)
      }
    })()
  }, [props, contract])

  const revokeOffer = async () => {
    if (!myOffer()) {
      return
    }

    dispatchRevoke({ type: 'init' })

    let { error } = await revokeTokenOffer(props.token.id, (err, txHash) => {
      if (err) {
        return dispatchRevoke({
          type: 'error',
          payload: { error: typeof err === 'string' ? err : err.message }
        })
      }
      dispatchRevoke({ type: 'start', payload: { hash: txHash } })
    })

    if (error) {
      return dispatchRevoke({ type: 'error', payload: { error } })
    }

    dispatchRevoke({
      type: 'success',
      payload: {
        message: `<span>Transaction completed, you have successfully revoked your offer for <b>${web3.utils.fromWei(
          myOffer()?.value || '0'
        )}</b> ETH. <br>Visit your <a href="/account" class="primary-link">account page</a> to withdraw your pending balance to your wallet</span>`
      }
    })

    const indexOfMyOffer = offers.findIndex((o) => o.address === myOffer()?.address)
    if (indexOfMyOffer > -1 && offers.length > indexOfMyOffer + 1) {
      props.token.highestOfferValue = offers[indexOfMyOffer + 1].value
      props.token.highestOfferAddress = offers[indexOfMyOffer + 1].address
    } else {
      props.token.highestOfferValue = '0'
      props.token.highestOfferAddress = ''
    }

    setOffers((prevOffers) => {
      return [...prevOffers.splice(indexOfMyOffer, 1)]
    })

    props.offerAction?.('revoked')
  }

  const acceptHighestOffer = async () => {
    dispatchAccept({ type: 'init' })

    let { error } = await acceptTokenOffer(props.token.id, offers[0].address, (err, txHash) => {
      if (err) {
        return dispatchAccept({
          type: 'error',
          payload: { error: typeof err === 'string' ? err : err.message }
        })
      }
      dispatchAccept({ type: 'start', payload: { hash: txHash } })
    })

    if (error) {
      return dispatchAccept({ type: 'error', payload: { error } })
    }

    dispatchAccept({
      type: 'success',
      payload: {
        message: `<span>Transaction completed, you have successfully accepted offer for <b>${web3.utils.fromWei(
          offers[0].value
        )}</b> ETH<br>Visit your <a href="/account" class="primary-link">account page</a> to withdraw your pending balance to your wallet</span>`
      }
    })

    props.token.address = offers[0].address
    props.token.highestOfferValue = '0'
    props.token.highestOfferAddress = ''

    setOffers([])

    props.offerAction?.('accepted')
  }

  const myOffer = () => {
    const myOfffers = offers.filter((o) => o.address === props.account)
    if (myOfffers.length > 0) {
      return myOfffers[0]
    }
    return null
  }

  return (
    <>
      {((offers.length > 0 && !props.account) || (myOffer() && props.account)) && (
        <div className='pr-5'>
          <div className='divide-y divide-gray-600 dark:divide-gray-50'>
            <div className='flex justify-between'>
              <span>Address</span>
              <span>Value</span>
            </div>

            {offers
              .filter((o) => o.address === props.account || !props.account)
              .map((offer) => (
                <div key={offer.address} className='flex justify-between pt-4'>
                  <span className='truncate pr-2' title={offer.address}>
                    {offer.address}
                  </span>
                  <span className='font-bold w-32 text-right'>
                    {formatNumber(+web3.utils.fromWei(offer.value, 'ether'))} ETH
                  </span>
                </div>
              ))}

            <div>&nbsp;</div>
          </div>

          <div>
            {props.account && myOffer() && (
              <button className='primary-button w-full sm:w-44 mx-auto' onClick={revokeOffer}>
                Revoke offer
              </button>
            )}
            {!props.account && offers.length > 0 && (
              <button
                className='primary-button w-full sm:w-64 mx-auto whitespace-nowrap'
                onClick={acceptHighestOffer}
              >
                Accept offer {formatNumber(+web3.utils.fromWei(offers[0].value || '0'))} ETH
              </button>
            )}
          </div>
        </div>
      )}

      <div className='pr-5'>
        {!props.account && offers.length === 0 && <p>There are no offers for this token yet</p>}
        {props.account && !myOffer() && <p>You did not send your offer to buy this token yet</p>}
      </div>

      <DialogOverlay
        message={revokeState.message}
        loading={revokeState.loading}
        error={revokeState.error}
        opened={revokeState.started}
        onClose={() => dispatchRevoke({ type: 'close' })}
      />

      <DialogOverlay
        message={acceptState.message}
        loading={acceptState.loading}
        error={acceptState.error}
        opened={acceptState.started}
        onClose={() => dispatchAccept({ type: 'close' })}
      />
    </>
  )
}

export default OfferList
