import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

function useQuery() {
    const history = useHistory()
    const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams(useLocation().search))

    useEffect(() => {
        const unlisten = history.listen((location) => {
            setQuery(new URLSearchParams(location.search))
        })
        return () => {
            unlisten()
        }
    }, [history])

    return query
}

export default useQuery
