import { Link } from 'react-router-dom'

function Footer() {
  const year = new Date().getFullYear()

  return (
    <div className='block sm:flex container mx-auto justify-between p-5 sm:p-10'>
      <div>Mega NFT © {year}</div>
      <div className='flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-x-10 sm:space-y-0 text-center'>
        <Link to='/terms'>
          <span className='primary-link'>Terms</span>
        </Link>
        <Link to='/home'>
          <span className='primary-link'>Home</span>
        </Link>
        <Link to='/about'>
          <span className='primary-link'>About</span>
        </Link>
        <Link to='/explore'>
          <span className='primary-link'>Explore</span>
        </Link>
        <a href='https://twitter.com/nft' target='_blank' rel='noreferrer'>
          <span className='primary-link'>
            Social
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4 ml-0.5 mb-1 inline align-middle'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
  )
}

export default Footer
