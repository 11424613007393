import Login from './Login/Login'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Registration from './Registration/Registration'
import Profile from './Profile/Profile'
import NotFound from '../NotFound/NotFound'
import AdminPanel from './AdminPanel/AdminPanel'

function Account() {
  const match = useRouteMatch()

  return (
    <div className='min-w-full'>
      <Switch>
        <Route exact path={`${match.url}`}>
          <Profile />
        </Route>
        <Route path={`${match.url}/login`}>
          <Login />
        </Route>
        <Route path={`${match.url}/registration`}>
          <Registration />
        </Route>
        <Route path={`${match.url}/admin`}>
          <AdminPanel />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </div>
  )
}

export default Account
