import useStorage from './use-storage'
import { useEffect } from 'react'
import { ConnectInfo, ProviderRpcError, Wallet } from '../types'

declare const window: Window | any

function useWallet(initValue: string | null = null) {
  const [wallet, setWallet] = useStorage(localStorage, 'wallet', initValue)

  useEffect(() => {
    if (!window.ethereum) {
      return
    }

    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      setWallet((prevWallet: Wallet) => {
        if (accounts.length === 0) {
          return null
        }
        return { ...prevWallet, connected: true, account: accounts[0] }
      })
    })

    window.ethereum.on('chainChanged', (chainId: string) => {
      setWallet((prevWallet: Wallet) => {
        return { ...prevWallet, chainId: chainId, connected: true }
      })
    })

    window.ethereum.on('connect', (info: ConnectInfo) => {
      setWallet((prevWallet: Wallet) => {
        return { ...prevWallet, connected: info.chainId === prevWallet?.chainId }
      })
    })

    window.ethereum.on('disconnect', (error: ProviderRpcError) => {
      setWallet((prevWallet: Wallet) => {
        return { ...prevWallet, connected: false }
      })
    })

    return () => {
      window.ethereum.removeAllListeners('accountsChanged')
      window.ethereum.removeAllListeners('chainChanged')
      window.ethereum.removeAllListeners('connect')
      window.ethereum.removeAllListeners('disconnect')
    }
  }, [setWallet])

  return [wallet, setWallet]
}

export default useWallet
