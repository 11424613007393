import { useEffect, useState } from 'react'

const { REACT_APP_PREFIX } = process.env

function getSavedValue(storage: Storage, key: string, initialValue: any = null) {
  const savedValue = storage.getItem(REACT_APP_PREFIX + '_' + key) as string
  if (savedValue) {
    return JSON.parse(savedValue)
  } else if (initialValue instanceof Function) {
    return initialValue()
  }
  return initialValue
}

function useStorage(storage: Storage, key: string, initialValue: any = null) {
  const [value, setValue] = useState(() => {
    return getSavedValue(storage, key, initialValue)
  })

  useEffect(() => {
    storage.setItem(REACT_APP_PREFIX + '_' + key, JSON.stringify(value))
  }, [storage, key, value])

  return [value, setValue]
}

export default useStorage
