import './DialogOverlay.css'
import { useCallback, useEffect, useRef } from 'react'

type WaitOverlyProps = {
  message: string
  opened: boolean
  loading?: boolean
  error?: boolean
  onClose?: () => void
}

function DialogOverlay(props: WaitOverlyProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const close = useCallback(() => {
    props.onClose?.()
  }, [props])

  useEffect(() => {
    if (props.opened) {
      containerRef.current?.classList.add('visible')
      containerRef.current?.classList.remove('invisible')
    } else {
      containerRef.current?.classList.add('invisible')
      containerRef.current?.classList.remove('visible')
    }
  }, [props])

  return (
    <div ref={containerRef} className='wait-overlay invisible' style={{ height: 'fit-content' }}>
      <div className='flex flex-col text-center h-full p-4'>
        <div
          className='absolute top-3 right-5 cursor-pointer transition duration-150 ease-in-out transform hover:scale-150'
          onClick={close}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </div>
        <div className='flex justify-center mt-4'>
          {props.loading && (
            <svg
              className='animate-spin h-28 w-28 text-yellow-500'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
          )}
          {!props.loading && !props.error && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-28 w-28 text-green-600 dark:text-green-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          )}
          {!props.loading && props.error && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-28 w-28 text-red-600 dark:text-red-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
              />
            </svg>
          )}
        </div>
        <div
          className='my-auto my-4 text-md sm:text-lg'
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      </div>
    </div>
  )
}

export default DialogOverlay
