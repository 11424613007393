import { Page } from './Header'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import './Menu.css'

type MenuProps = {
  pages: Page[]
  toggleMenu: () => void
}

function Menu(props: MenuProps) {

  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <div className='menu-overlay'>
      <ul className='flex flex-col space-y-6 items-center text-center'>
        {props.pages.map((page, i) => (
          <Link key={`${i}${page.title}${page.url}`} to={page.url} onClick={props.toggleMenu}>
            {!page.isButton ? (
              <li className='text-lg hover:underline font-bold text-lg'>{page.title}</li>
            ) : (
              <button className='primary-button font-bold text-lg'>{page.title}</button>
            )}
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default Menu
