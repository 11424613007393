import { User } from '../types'

const { REACT_APP_API } = process.env

async function login(email: string, password: string): Promise<{ success: boolean; data: any }> {
  let resp = await fetch(`${REACT_APP_API}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  })
  const data = await resp.json()
  return { success: resp.ok, data }
}

async function logout(accessToken: string): Promise<boolean> {
  let resp = await fetch(`${REACT_APP_API}/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  return resp.ok
}

async function register(userData: User): Promise<{ success: boolean; data: any }> {
  let resp = await fetch(`${REACT_APP_API}/register`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  })
  const data = await resp.json()
  return { success: resp.ok, data }
}

async function updateUser(
  accessToken: string,
  userData: User
): Promise<{ success: boolean; data: any }> {
  let resp = await fetch(`${REACT_APP_API}/users/${userData.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  })
  const data = await resp.json()
  return { success: resp.ok, data }
}

async function authUser(accessToken: string): Promise<{ success: boolean; data: any }> {
  let resp = await fetch(`${REACT_APP_API}/users/me`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  const data = await resp.json()
  return { success: resp.ok, data }
}

export const userService = {
  login,
  logout,
  register,
  updateUser,
  authUser
}
