import { createContext, useContext, useEffect, useState } from 'react'
import { AccessToken, User } from './shared/types'
import useStorage from './shared/hooks/use-storage'
import { userService } from './shared/service'

const { REACT_APP_PREFIX } = process.env

type AppContextData = {
  user: User | null
  setUser: any
  accessToken: AccessToken | null
  setAccessToken: any
  dark: boolean
  setDark: any
}

const AppContext = createContext<AppContextData>({
  user: null,
  setUser: undefined,
  accessToken: null,
  setAccessToken: undefined,
  dark: false,
  setDark: undefined
})

function AppContextProvider({ children }: any) {
  const [user, setUser] = useState(null)
  const tokenStorage =
    localStorage.getItem(REACT_APP_PREFIX + '_storage') === 'local' ? localStorage : sessionStorage
  const [accessToken, setAccessToken] = useStorage(tokenStorage, 'access_token', null)
  const osDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [dark, setDark] = useStorage(localStorage, 'dark', osDarkTheme)

  useEffect(() => {
    ;(async () => {
      if (accessToken) {
        let { success, data } = await userService.authUser(accessToken.accessToken)
        if (success) {
          setUser(data)
          return
        }
        setAccessToken(null)
      }
      setUser(null)
    })()
  }, [accessToken, setAccessToken])

  useEffect(() => {
    if (dark) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [dark])

  const appContext: AppContextData = {
    user,
    setUser,
    accessToken,
    setAccessToken,
    dark,
    setDark
  }

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}

const useAppContext = () => useContext(AppContext)

export { AppContextProvider, useAppContext }
