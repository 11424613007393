import { NavLink } from 'react-router-dom'
import { useAppContext } from '../../AppContext'
import { useState } from 'react'
import Menu from './Menu'

const { REACT_APP_NAME } = process.env

export type HeaderProps = {
  logo: string
  pages: Page[]
}

export type Page = {
  title: string
  url: string
  isButton?: boolean
}

function Header(props: HeaderProps) {
  const { dark, setDark } = useAppContext()
  const [menu, setMenu] = useState(false)

  const changeTheme = () => {
    setDark(!dark)
  }

  const toggleMenu = () => {
    setMenu(!menu)
  }

  return (
    <>
      <div className='bg-blue-600 dark:bg-blue-900 py-3 shadow-md sticky top-0 z-50'>
        <div className='flex container mx-auto justify-between'>
          <div className='flex items-center ml-5 sm:ml-0'>
            <NavLink to='/' className='flex items-center' onClick={() => setMenu(false)}>
              <img src={props.logo} width='50px' alt='Logo' />
              <span className='pl-2 text-lg text-yellow-300 font-bold'>{REACT_APP_NAME}</span>
            </NavLink>
            <span className='ml-4 cursor-pointer text-yellow-300' onClick={changeTheme}>
              {!dark ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z' />
                </svg>
              )}
            </span>
          </div>

          <ul className='space-x-10 items-center hidden sm:flex'>
            {props.pages.map((page, i) => (
              <NavLink key={`${i}${page.title}${page.url}`} to={page.url}>
                {!page.isButton ? (
                  <li className='text-lg text-yellow-300 hover:underline'>{page.title}</li>
                ) : (
                  <button className='primary-button font-bold text-lg'>{page.title}</button>
                )}
              </NavLink>
            ))}
          </ul>

          <div
            className='items-center flex mr-5 sm:hidden cursor-pointer text-white'
            onClick={toggleMenu}
          >
            {menu ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
            )}
          </div>
        </div>
      </div>

      {menu && <Menu pages={props.pages} toggleMenu={toggleMenu} />}
    </>
  )
}

export default Header
