import { Link, useHistory, useParams } from 'react-router-dom'
import useApi from '../../shared/hooks/use-api'
import { Token } from '../../shared/types'
import TokenCard from '../../components/TokenCard/TokenCard'
import TransactionTable from '../../components/TransactionsTable/TransactionTable'
import { useWeb3Context, web3 } from '../../Web3Context'
import { formatNumber, rarityName } from '../../shared/utils'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { tokenService } from '../../shared/service'
import { useAppContext } from '../../AppContext'
import classNames from 'classnames'
import useContract from '../../shared/hooks/use-contract'
import DialogOverlay from '../../components/DialogOverlay/DialogOverlay'
import OfferList from '../../components/OfferList/OfferList'
import pendingTransactionReducer from '../../shared/reducers/pending-transaction-reducer'

type TokenPageParams = {
  id: string
}

type SaleType = 'sale' | 'offer' | 'noSale'

function TokenPage() {
  const { id } = useParams<TokenPageParams>()
  const history = useHistory()
  const { accessToken, user } = useAppContext()
  const { wallet } = useWeb3Context()
  const { setTokenNotForSale, setTokenPrice } = useContract()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const { data: token, error } = useApi<Token>(`/tokens/${id}`)
  const [editing, setEditing] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>('')
  const [type, setType] = useState<SaleType>('sale')
  const [tempValues, setTempValues] = useState<{ value: string | undefined; type: SaleType }>({
    value: value,
    type: type
  })
  const [state, dispatch] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })
  const valueRef = useRef<HTMLInputElement>(null)

  const initializeType = useCallback(() => {
    if (!valueRef.current) {
      return
    }
    switch (type) {
      case 'sale':
        valueRef.current.disabled = false
        valueRef.current.placeholder = 'e.g. 1.25'
        break
      case 'offer':
        valueRef.current.disabled = true
        valueRef.current.placeholder = '-'
        break
      case 'noSale':
        valueRef.current.disabled = true
        valueRef.current.placeholder = '-'
    }
  }, [type, valueRef])

  useEffect(() => {
    if (error) {
      history.push('/404')
    }
  }, [error, history])

  useEffect(() => {
    if (token && token.value) {
      setValue(web3.utils.fromWei(token.value))
      setType(+token.value === 0 ? 'offer' : 'sale')
    } else {
      setType('noSale')
    }
  }, [token])

  useEffect(() => {
    initializeType()
  }, [initializeType, editing])

  const tokenEvent = useCallback(() => {
    setUpdateCount((prevCount) => prevCount + 1)
  }, [])

  const offerEvent = useCallback(() => {
    setUpdateCount((prevCount) => prevCount + 1)
  }, [])

  const setPrice = async (tokenId: string, price: string) => {
    dispatch({ type: 'init' })

    const cb = async (err: any, txHash: any) => {
      if (err) {
        return dispatch({
          type: 'error',
          payload: { error: typeof err === 'string' ? err : err.message }
        })
      }
      dispatch({ type: 'start', payload: { hash: txHash } })

      if (token && accessToken) {
        token.updating = true
        let updatedToken = { ...token, value: price, updating: true }
        let { success } = await tokenService.updateToken(updatedToken, accessToken.accessToken)
        if (success) {
          if (+price > 0) {
            setValue(web3.utils.fromWei(price))
          } else {
            setValue(price)
          }
          token.value = price
          token.type = price === '' ? 'not for sale' : price === '0' ? 'open to offers' : 'for sale'
        }
      }
    }

    let error
    if (+price >= 0 && price !== '') {
      ;({ error } = await setTokenPrice(tokenId, price, cb))
    } else {
      ;({ error } = await setTokenNotForSale(tokenId, cb))
    }

    if (token) {
      token.updating = false
    }

    if (error) {
      if (token && accessToken) {
        await tokenService.updateToken(
          {
            ...token,
            value: tempValues.value as string,
            updating: false
          },
          accessToken.accessToken
        )
        cancelEditing()
      }
      return dispatch({ type: 'error', payload: { error } })
    }

    setTimeout(() => {
      dispatch({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, token changes are now in effect.</span><br>`
        }
      })
      setUpdateCount((prevCount) => prevCount + 1)
    }, 1000)
  }

  const isOwner = (): boolean => {
    return wallet && wallet.account && wallet.account.toLowerCase() === token?.address.toLowerCase()
  }

  const editToken = async () => {
    if (editing && token && accessToken) {
      let newValue
      if (type === 'sale' && value !== '') {
        newValue = web3.utils.toWei(value as string)
      } else if (type === 'offer') {
        newValue = '0'
      } else {
        newValue = ''
      }

      await setPrice(token.id, newValue)
    }

    if (!editing) {
      setTempValues({ value, type })
    }

    setEditing((prevEditing) => {
      return !prevEditing
    })
  }

  const changeValue = (newValue: string) => {
    setValue(newValue)
  }

  const cancelEditing = () => {
    setValue(tempValues.value)
    setType(tempValues.type)
    setEditing(false)
  }

  return (
    <div className='min-w-full space-y-10 text-center'>
      <h1 className='page-title pl-5 pt-5 pr-5'>Token #{id}</h1>
      <div className='space-y-5 px-5'>
        {!token && <div className='token-container animate-pulse' />}
        {token && <TokenCard token={token} tokenAction={tokenEvent} />}
        <div className='break-all text-lg text-left space-y-4 max-w-2xl xl:max-w-xl mx-auto'>
          <div>
            <b>Owner:</b>{' '}
            <span className='sm:inline block text-sm sm:text-lg'>{token?.address}</span>
          </div>
          <div>
            <b>Rarity: </b>
            <span
              className={classNames('sm:inline block', {
                'font-bold': token?.rarity !== 1,
                [rarityName(token?.rarity).toLowerCase()]: true
              })}
            >
              {rarityName(token?.rarity)}
            </span>
          </div>
          <div>
            <b>Description: </b>
            <span style={{ textTransform: 'capitalize' }} className='sm:inline block'>
              {token?.imageDescription}
            </span>
          </div>
          <div>
            <b>Value: </b>
            <span className='sm:inline block'>
              {!editing &&
                (value && +value > 0
                  ? formatNumber(+value) + ' ETH'
                  : !value
                  ? 'Not for sale'
                  : 'Open to offers ' +
                    (token?.highestOfferAddress
                      ? `(highest: ${web3.utils.fromWei(
                          token?.highestOfferValue as string,
                          'ether'
                        )} ETH)`
                      : ''))}
            </span>
            {editing && (
              <>
                <input
                  ref={valueRef}
                  onKeyUp={(e) => (e.key === 'Enter' ? editToken() : null)}
                  type='number'
                  className='input-field focus-ring w-40 inline'
                  placeholder='e.g. 1.25'
                  min={0}
                  value={value}
                  onChange={(e) => changeValue(e.target.value)}
                />
                <span className='ml-2'>ETH</span>
                <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between mt-4'>
                  <label>
                    <input
                      type='radio'
                      name='saleType'
                      className='input-checkbox'
                      value='sale'
                      checked={type === 'sale'}
                      onChange={() => setType('sale')}
                    />
                    <span className='ml-2 align-middle'>For sale</span>
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='saleType'
                      className='input-checkbox'
                      value='offer'
                      checked={type === 'offer'}
                      onChange={() => setType('offer')}
                    />
                    <span className='ml-2 align-middle'>Open to offers</span>
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='saleType'
                      className='input-checkbox'
                      value='noSale'
                      checked={type === 'noSale'}
                      onChange={() => setType('noSale')}
                    />
                    <span className='ml-2 align-middle'>Not for sale</span>
                  </label>
                </div>
              </>
            )}
          </div>
          {isOwner() && accessToken && editing && (
            <span className='absolute hidden sm:inline primary-link pt-2' onClick={cancelEditing}>
              Cancel
            </span>
          )}
        </div>
        {isOwner() && accessToken && user && user.signature && (
          <button className='primary-button font-bold w-full sm:w-52' onClick={editToken}>
            {!editing ? 'Edit value' : 'Save'}
          </button>
        )}
        {isOwner() && accessToken && user && user.signature && editing && (
          <span className='block sm:hidden mt-12 primary-link' onClick={cancelEditing}>
            Cancel
          </span>
        )}

        {isOwner() && !accessToken && (
          <div className='well'>
            To change value of this token please{' '}
            <Link to='/account/login' className='primary-link'>
              Sign in
            </Link>
          </div>
        )}

        {isOwner() && accessToken && user && !user.signature && (
          <div className='well'>
            To change value of this token please{' '}
            <Link to='/account' className='primary-link'>
              Sign your wallet
            </Link>
          </div>
        )}
      </div>

      <div>
        {token &&
          token.type === 'open to offers' &&
          (token.address === wallet?.account ? (
            <div className='flex flex-col space-y-4 max-w-2xl 2xl:max-w-4xl mx-auto pl-5'>
              <h2>Offers</h2>
              <OfferList token={token} offerAction={offerEvent} />
            </div>
          ) : (
            <div className='flex flex-col space-y-4 max-w-2xl 2xl:max-w-4xl mx-auto pl-5'>
              <h2>My offer</h2>
              <OfferList token={token} account={wallet?.account} offerAction={offerEvent} />
            </div>
          ))}
      </div>

      {token && (
        <div className='flex flex-col space-y-4 max-w-2xl 2xl:max-w-4xl mx-auto pl-5'>
          <h2>Transaction history</h2>
          <TransactionTable key={updateCount} identifier={token.id} />
        </div>
      )}

      <DialogOverlay
        message={state.message}
        loading={state.loading}
        error={state.error}
        opened={state.started}
        onClose={() => dispatch({ type: 'close' })}
      />
    </div>
  )
}

export default TokenPage
