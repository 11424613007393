import { useWeb3Context, web3 } from '../../Web3Context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { tokenService, web3Service } from '../service'
import useStorage from './use-storage'
import useMessage, { SubscriptionType } from './use-message'
import { useHistory } from 'react-router-dom'

function useContract() {
  const history = useHistory()
  const { contract, wallet, setWallet } = useWeb3Context()
  const [totalSupply, setTotalSupply] = useStorage(sessionStorage, 'total_supply', null)
  const [mintValue, setMintValue] = useState<string>('0')
  const [tokenBalance, setTokenBalance] = useState<number>(0)
  const [pendingBalance, setPendingBalance] = useState<string>('0')
  const [contractOwner, setContractOwner] = useState<string>('')

  const transferMessageOptions = useMemo(() => {
    if (!contract) {
      return null
    }
    return {
      address: contract?.options?.address,
      topics: [
        web3.utils.sha3('Transfer(address,address,uint256)'),
        web3.utils.padLeft('0x', 64), // From zero address
        null,
        null
      ]
    }
  }, [contract])

  const transferMessage = useMessage(SubscriptionType.LOGS, transferMessageOptions)

  const refreshTotalSupply = useCallback(async () => {
    if (!contract) {
      if (contract === null) {
        setTotalSupply(0)
        setContractOwner('')
      }
      return
    }
    let supply = await contract.methods.totalSupply().call()
    setTotalSupply(supply)
  }, [contract, setTotalSupply])

  useEffect(() => {
    if (contract === null) {
      history.push('/network-not-supported')
      return
    }

    if (!contract) {
      return
    }

    ;(async () => {
      const ownerAccount = await contract.methods.owner().call()
      setContractOwner(ownerAccount.toLowerCase())

      const price = await contract.methods.mintPrice().call()
      setMintValue(price)
    })()
  }, [contract, history])

  useEffect(() => {
    if (!transferMessage) {
      return
    }

    // Refresh supply when received Transfer event message from zero address
    ;(async () => {
      await refreshTotalSupply()
    })()
  }, [transferMessage, contract, refreshTotalSupply])

  useEffect(() => {
    // Get initial account values from contract
    ;(async () => {
      await refreshTotalSupply()

      if (wallet && wallet.account && contract) {
        let balance = await contract.methods.balanceOf(wallet.account).call()
        setTokenBalance(balance)

        let pendingBalance = await contract.methods.pendingBalanceOf(wallet.account).call()
        setPendingBalance(web3.utils.fromWei(pendingBalance || '0', 'ether'))
      } else {
        setTokenBalance(0)
        setPendingBalance('0')
      }
    })()
  }, [contract, wallet, refreshTotalSupply])

  const connectOrGetWallet = async () => {
    let connectedWallet = wallet
    if (!connectedWallet) {
      try {
        connectedWallet = await web3Service.connectWallet()
        setWallet(connectedWallet)
      } catch (err) {
        return [err, null]
      }
    }
    return [null, connectedWallet]
  }

  const sendContractTransaction = async (
    methodName: string,
    methodParams: any[],
    sendParams: any,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    if (!contract) {
      return { error: 'Contract not deployed', tx: null }
    }

    let tx
    try {
      tx = await contract.methods[methodName](...methodParams).send(sendParams, callback)
    } catch (err) {
      return { error: err.message, tx: null }
    }

    return { error: null, tx }
  }

  const mintToken = async (
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    const { data: token } = await tokenService.generateId()

    return sendContractTransaction(
      'mint',
      [connectedWallet.account, token.id],
      {
        from: connectedWallet.account,
        value: mintValue
      },
      callback || (() => {})
    )
  }

  const withdrawPendingBalance = async (
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    let result = await sendContractTransaction(
      'withdrawPendingBalance',
      [],
      { from: connectedWallet.account },
      callback || (() => {})
    )

    if (!result.error) {
      setPendingBalance('0')
    }

    return result
  }

  const withdrawContractFunds = async (
    amount: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return await sendContractTransaction(
      'withdrawFunds',
      [amount],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const bulkMintTokens = async (
    tokenIds: string[],
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return await sendContractTransaction(
      'bulkMint',
      [tokenIds],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const transferToken = async (
    from: string,
    to: string,
    tokenId: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return await sendContractTransaction(
      'transferFrom',
      [from, to, tokenId],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const burnToken = async (
    tokenId: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return await sendContractTransaction(
      'burn',
      [tokenId],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const setTokenNotForSale = async (
    tokenId: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'setTokenNotForSale',
      [tokenId],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const setTokenPrice = async (
    tokenId: string,
    price: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'setTokenPrice',
      [tokenId, price],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const buyToken = async (
    tokenId: string,
    value: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'buyToken',
      [tokenId],
      { from: connectedWallet.account, value: value },
      callback || (() => {})
    )
  }

  const makeTokenOffer = async (
    tokenId: string,
    value: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'makeTokenOffer',
      [tokenId],
      { from: connectedWallet.account, value: value },
      callback || (() => {})
    )
  }

  const revokeTokenOffer = async (
    tokenId: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'revokeTokenOffer',
      [tokenId],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  const acceptTokenOffer = async (
    tokenId: string,
    offerAddress: string,
    callback?: (err: any, tx: any) => void
  ): Promise<{ error: string | null; tx: any }> => {
    let [err, connectedWallet] = await connectOrGetWallet()
    if (err) {
      return { error: err.message, tx: null }
    }

    return sendContractTransaction(
      'acceptTokenOffer',
      [tokenId, offerAddress],
      { from: connectedWallet.account },
      callback || (() => {})
    )
  }

  return {
    totalSupply,
    tokenBalance,
    pendingBalance,
    contractOwner,
    mintValue,
    mintToken,
    withdrawPendingBalance,
    withdrawContractFunds,
    bulkMintTokens,
    transferToken,
    burnToken,
    setTokenNotForSale,
    setTokenPrice,
    buyToken,
    makeTokenOffer,
    revokeTokenOffer,
    acceptTokenOffer
  }
}

export default useContract
