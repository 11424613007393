import { useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useAppContext } from '../../../AppContext'
import { userService } from '../../../shared/service'
import classNames from 'classnames'

const { REACT_APP_PREFIX } = process.env

function Login() {
  const { accessToken, setAccessToken } = useAppContext()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const submitLogin = async () => {
    setSubmitted(true)

    if (!email || !password) {
      return
    }

    setLoading(true)

    let { success, data } = await userService.login(email, password)

    if (success) {
      setAccessToken(() => {
        localStorage.setItem(REACT_APP_PREFIX + '_storage', rememberMe ? 'local' : 'session')
        return data
      })

      setLoading(false)
      history.push('/account')
      return
    }

    setLoading(false)
    setError(data.error)
  }

  if (accessToken) {
    return <Redirect to='/account' />
  }

  return (
    <div className='block max-w-md mx-auto p-5'>
      <h1 className='page-title'>Sign in</h1>
      <form className='space-y-6' onKeyUp={(e) => (e.key === 'Enter' ? submitLogin() : null)}>
        <label className='block space-y-2'>
          <span>E-mail</span>
          <input
            type='email'
            placeholder='Enter e-mail'
            autoComplete='username'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': submitted && !email
            })}
            onChange={(e) => setEmail(e.target.value)}
          />
          {submitted && !email && <small className='error-text'>Please enter your e-mail</small>}
        </label>

        <label className='block space-y-2'>
          <span>Password</span>
          <input
            type='password'
            placeholder='Enter password'
            autoComplete='current-password'
            className={classNames('input-field focus-ring', {
              'focus-ring-error': submitted && !email
            })}
            onChange={(e) => setPassword(e.target.value)}
          />
          {submitted && !password && (
            <small className='error-text'>Please enter your password</small>
          )}
          {submitted && error && <small className='error-text'>{error}</small>}
        </label>

        <label className='block space-y-2'>
          <input
            id='remember-me'
            type='checkbox'
            placeholder='Remember me'
            className='input-checkbox rounded'
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <span className='ml-2 align-middle'>Remember me on this device</span>
        </label>

        <div className='sm:flex block items-center justify-between'>
          <button
            type='button'
            className='primary-button w-full sm:w-48 order-last'
            disabled={loading}
            onClick={submitLogin}
          >
            Login
          </button>
          <Link to={`/account/registration`} className='invisible sm:visible'>
            <span className='primary-link mx-auto'>Create account</span>
          </Link>
        </div>

        <div className='sm:invisible text-center'>
          Not yet registered?{' '}
          <Link to={`/account/registration`} className='primary-link'>
            Create account
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Login
