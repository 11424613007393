import { useCallback, useEffect, useState } from 'react'
import useApi from '../../shared/hooks/use-api'
import { TokensApiData } from '../../shared/types'
import Pagination from '../../components/Pagination/Pagination'
import TokenCard from '../../components/TokenCard/TokenCard'
import { Link, useHistory } from 'react-router-dom'
import useQuery from '../../shared/hooks/use-query'
import TokenFilter from '../../components/TokenFilter/TokenFilter'
import useContract from '../../shared/hooks/use-contract'

function getOptimalPageSize() {
  const width = window.innerWidth
  let newSize
  if (width < 768) {
    newSize = 10
  } else if (width < 1024) {
    newSize = 15
  } else if (width < 1536) {
    newSize = 20
  } else {
    newSize = 25
  }
  return newSize
}

function getCurrentPage(query: URLSearchParams): number {
  return query.has('page') ? +(query.get('page') as string) : 1
}

function getCurrentFilters(query: URLSearchParams): string {
  return query.has('filters') ? (query.get('filters') as string) : ''
}

function Explore() {
  const history = useHistory()
  const query = useQuery()
  const { totalSupply } = useContract()
  const [size, setSize] = useState<number>(() => getOptimalPageSize())
  const [page, setPage] = useState<number>(getCurrentPage(query))
  const [filters, setFilters] = useState<string>(getCurrentFilters(query))
  const { data: response, loading } = useApi<TokensApiData>(
    `/tokens/query?page=${page}&size=${size}&filters=${filters}`
  )

  useEffect(() => {
    let currentPage = getCurrentPage(query)
    if (page !== currentPage) {
      setPage(currentPage)
    }
  }, [query, page])

  useEffect(() => {
    let currentFilters = getCurrentFilters(query)
    if (filters !== currentFilters) {
      setFilters(currentFilters)
    }
  }, [query, filters])

  useEffect(() => {
    function changeSizes() {
      let newSize = getOptimalPageSize()
      if (size !== newSize) {
        setSize(newSize)
        history.push({
          search: `?page=1&filters=${filters}`
        })
      }
    }

    window.addEventListener('resize', changeSizes)
    return () => {
      window.removeEventListener('resize', changeSizes)
    }
  }, [size, history, filters])

  const changePage = useCallback(
    (newPage: number) => {
      history.push({
        search: `?page=${newPage}&filters=${filters}`
      })

      window.scrollTo({ top: 0 })
    },
    [history, filters]
  )

  const changeFilters = useCallback(
    (newFilters: string) => {
      history.push({
        search: `?page=1&filters=${newFilters}`
      })
    },
    [history]
  )

  return (
    <div className='page-container'>
      <h1 className='page-title'>Explore MEGA tokens</h1>

      {response && response.totalCount === 0 && +totalSupply === 0 && (
        <div className='well text-center mt-10 space-y-4'>
          <div>There are no tokens mined so far</div>
          <div>
            Be first and{' '}
            <Link to='/claim' className='primary-link'>
              claim your token now!
            </Link>
          </div>
        </div>
      )}

      {response && response.data && +totalSupply > 0 && (
        <div className='pb-10'>
          <TokenFilter key={filters} filtersChanged={changeFilters} filtersQuery={filters} />

          {response && response.resultCount === 0 && response.totalCount > 0 && (
            <div className='well flex flex-col text-center mt-10 space-y-4'>
              <div>This page is empty!</div>
              <Link to='/explore?page=1' className='primary-link'>
                Open first page
              </Link>
            </div>
          )}

          {response && response.totalCount === 0 && +totalSupply > 0 && (
            <div className='well flex flex-col text-center mt-10 space-y-4'>
              <div>No results found for current filters</div>
              <Link to='/explore?page=1' className='primary-link'>
                Reset filters
              </Link>
            </div>
          )}

          <div className='block sm:hidden pb-14'>
            <Pagination
              page={page}
              size={size}
              totalCount={response.totalCount}
              pageChanged={changePage}
            />
          </div>

          <div
            className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-8 sm:gap-x-0 md:gap-x-20 lg:gap-x-10 xl:gap-x-0 sm:gap-y-14 mb-6'
            style={{ opacity: loading ? '0.7' : '1' }}
          >
            {response?.data.map((token) => (
              <TokenCard key={token.id} token={token} />
            ))}
          </div>

          <Pagination
            page={page}
            size={size}
            totalCount={response.totalCount}
            pageChanged={changePage}
          />
        </div>
      )}
    </div>
  )
}

export default Explore
