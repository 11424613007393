import { useCallback, useEffect, useRef, useState } from 'react'
import useApi from '../../shared/hooks/use-api'
import { Token, TokensApiData } from '../../shared/types'
import TokenCard from '../TokenCard/TokenCard'
import TokenFilter from '../TokenFilter/TokenFilter'
import { useHistory } from 'react-router-dom'
import useQuery from '../../shared/hooks/use-query'

type TokenListProps = {
  address: string
  hideFilters?: boolean
  maxCount?: number
}

type TokenListState = {
  address: string
  page: number
  size: number
  filters: string
  tokens: Token[]
}

function getCurrentFilters(query: URLSearchParams): string {
  return query.has('filters') ? (query.get('filters') as string) : ''
}

function TokenList(props: TokenListProps) {
  const history = useHistory()
  const query = useQuery()
  const listRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<TokenListState>({
    address: props.address,
    page: 1,
    size: 15,
    filters: props.hideFilters ? '' : getCurrentFilters(query),
    tokens: []
  })
  const { data: response, loading } = useApi<TokensApiData>(
    `/tokens/query?address=${state.address}&page=${state.page}&filters=${state.filters}&size=${state.size}`
  )

  useEffect(() => {
    let currentFilters = getCurrentFilters(query)
    if (state.filters !== currentFilters) {
      setState((prevState) => {
        return { ...prevState, filters: currentFilters, tokens: [], page: 1 }
      })
    }
  }, [query, state])

  useEffect(() => {
    if (!response || !response.data) {
      return
    }

    setState((prevState) => {
      const filterdData = response.data.filter(
        (t) => !prevState.tokens.find((pt) => pt.id === t.id)
      )
      return {
        ...prevState,
        tokens: [...prevState.tokens, ...filterdData]
      }
    })
  }, [response])

  const scrolled = (e: any) => {
    if (!response || (props.maxCount && state.tokens.length >= props.maxCount)) {
      return
    }
    const totalPages = Math.round(Math.ceil(response.totalCount / state.size))
    const distance = e.target.scrollLeft + e.target.offsetWidth
    if (
      distance / e.target.scrollWidth > 0.95 &&
      e.target.scrollLeft > 0 &&
      state.page < totalPages &&
      !loading
    ) {
      setState((prevState) => {
        return { ...prevState, page: prevState.page + 1 }
      })
    }
  }

  const changeFilters = useCallback(
    (newFilters: string) => {
      history.push({
        search: `?filters=${newFilters}`
      })
    },
    [history]
  )

  return (
    <>
      <div className='ml-4 xl:ml-0 pr-5'>
        {!props.hideFilters && (
          <TokenFilter
            key={state.filters}
            filtersChanged={changeFilters}
            filtersQuery={state.filters}
          />
        )}
      </div>
      <div
        ref={listRef}
        className='grid grid-rows-1 grid-flow-col gap-x-6 overflow-x-auto pt-2 pb-8 pl-5'
        onScroll={scrolled}
      >
        {state.tokens &&
          state.tokens.map((token) => <TokenCard key={token.id} token={token} hideOwner={true} />)}
        {!loading && <div className='px-1' />}
        {loading && (
          <div className='h-72 ml-16 flex items-center'>
            <svg
              className='animate-spin h-16 w-16 text-yellow-500'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
          </div>
        )}
      </div>
    </>
  )
}

export default TokenList
