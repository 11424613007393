import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header/Header'
import Home from './pages/Home/Home'
import Account from './pages/Account/Account'
import Explore from './pages/Explore/Explore'
import Footer from './components/Footer/Footer'
import About from './pages/About/About'
import Terms from './pages/Terms/Terms'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import { useAppContext } from './AppContext'
import { useEffect, useState } from 'react'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Claim from './pages/Claim/Claim'
import { useWeb3Context } from './Web3Context'
import { userService } from './shared/service'
import NotFound from './pages/NotFound/NotFound'
import TokenPage from './pages/Explore/TokenPage'
import NetworkNotSupported from './pages/NetworkNotSupported/NetworkNotSupported'

function App() {
  const { accessToken, user, setUser } = useAppContext()
  const { wallet } = useWeb3Context()
  const [navPages] = useState([
    { title: 'About', url: '/about' },
    { title: 'Claim', url: '/claim' },
    { title: 'Explore', url: '/explore' },
    { title: 'My account', url: '/account', isButton: true }
  ])

  useEffect(() => {
    if (!wallet || !user || !accessToken) {
      return
    }
    if (
      user.addresses
        ?.filter((a) => a !== null && a !== undefined)
        .map((a) => a.toLowerCase())
        .indexOf(wallet.account) === -1
    ) {
      const addedAddress = user.addresses?.concat([wallet.account])
      const updatedUser = {
        ...user,
        addresses: addedAddress
      }

      ;(async () => {
        let { success } = await userService.updateUser(accessToken.accessToken, updatedUser)
        if (success) {
          setUser(updatedUser)
        }
      })()
    }
  }, [wallet, user, accessToken, setUser])

  return (
    <Router>
      <ScrollToTop />

      <Header logo='/icon-192x192.png' pages={navPages} />

      <div className='content-container'>
        <Switch>
          <Route exact path={['/', '/home']}>
            <Home />
          </Route>
          <Route path='/claim' component={Claim} />

          <Route path='/explore/:id' component={TokenPage} />
          <Route path='/explore' component={Explore} />

          <Route path='/account' component={Account} />
          <Route path='/about' component={About} />
          <Route path='/terms' component={Terms} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/network-not-supported' component={NetworkNotSupported} />
          <Route component={NotFound} />
        </Switch>
      </div>

      <Footer />
    </Router>
  )
}

export default App
