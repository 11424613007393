import { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

function HowToClaim() {
  const [open1, setOpen1] = useState<boolean>(false)
  const [open2, setOpen2] = useState<boolean>(false)

  const toggleAccordion = (index: number) => {
    if (index === 1) {
      setOpen1((prevState) => !prevState)
    } else if (index === 2) {
      setOpen2((prevState) => !prevState)
    }
  }

  return (
    <div className='prose prose-md sm:prose-lg mx-auto mt-16'>
      <h2 className='text-center'>How to claim a token?</h2>
      <div className='accordion-header' onClick={() => toggleAccordion(1)}>
        <span>
          <span className='text-sm'>
            {open1 ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 mb-1 inline'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M19 9l-7 7-7-7'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 mb-1 inline'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M9 5l7 7-7 7'
                />
              </svg>
            )}
          </span>{' '}
          I never used an ETH Wallet
        </span>
      </div>
      <div className={classNames('accordion-body', { hidden: !open1 })}>
        <ul className='text-base'>
          <li>We recommend using MetaMask (free to use and highly secure)</li>
          <li>
            <a
              className='primary-link'
              href='https://metamask.io/download.html'
              target='_blank'
              rel='noreferrer'
            >
              Download MetaMask
            </a>{' '}
            and set it up
          </li>
          <li>Buy or Transfer at least 0.1 ETH (you may need more)</li>
          <li>
            Go to{' '}
            <Link to='/account' className='primary-link'>
              Account page
            </Link>
          </li>
          <li>Click on "Connect Wallet"</li>
          <li>
            Click on the compatible protocole or Scan the QR code if you downloaded MetaMask on
            mobile
          </li>
          <li>
            <a className='primary-link' href={'#claim'}>
              Claim
            </a>{' '}
            your first card
          </li>
        </ul>
      </div>

      <div className='accordion-header' onClick={() => toggleAccordion(2)}>
        <span>
          <span className='text-sm'>{open2 ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 mb-1 inline'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M19 9l-7 7-7-7'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 mb-1 inline'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 5l7 7-7 7'
              />
            </svg>
          )}</span> I already have an ETH Wallet
        </span>
      </div>
      <div className={classNames('accordion-body', { hidden: !open2 })}>
        <ul className='text-base'>
          <li>
            Go to{' '}
            <Link to='/account' className='primary-link'>
              Account page
            </Link>
          </li>
          <li>Click on "Connect Wallet"</li>
          <li>
            Click on the compatible protocole or Scan the QR code if you downloaded MetaMask on
            mobile
          </li>
          <li>
            <a className='primary-link' href={'#claim'}>
              Claim
            </a>{' '}
            your first card
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HowToClaim
