import { TransactionAction, TransactionState } from '../types'

const { REACT_APP_ETHERSCAN } = process.env

function pendingTransactionReducer(state: TransactionState, action: TransactionAction) {
  switch (action.type) {
    case 'init':
      return {
        loading: false,
        started: false,
        message: action.payload?.message || 'Please accept the transaction in your wallet'
      }
    case 'start':
      return {
        loading: true,
        started: true,
        message:
          action.payload?.message ||
          `<span>
            Pending transaction is being mined, meanwhile you can track transaction with the following
            hash:<a class="block font-bold break-all primary-link" href="${REACT_APP_ETHERSCAN}/tx/${action.payload?.hash}" target="_blank" rel="noreferrer">${action.payload?.hash}</a>
          </span>`
      }
    case 'success':
      return {
        ...state,
        loading: false,
        message:
          action.payload?.message ||
          `<span>Transaction completed!</span><br>`
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: true,
        message:
          action.payload?.message ||
          `<span>Error occured during transaction processing:<br>${action.payload?.error}</span>`
      }
    case 'close':
      return { ...state, started: false }
    default:
      return state
  }
}

export default pendingTransactionReducer
