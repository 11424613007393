import { useCallback, useMemo } from 'react'
import classNames from 'classnames'

type PaginationProps = {
  page: number
  size: number
  totalCount: number
  pageChanged: (page: number) => void
}

function Pagination(props: PaginationProps) {
  const totalPages = useMemo(() => {
    return Math.round(Math.ceil(props.totalCount / props.size))
  }, [props])

  const pageChanged = useCallback(
    (newPage: number) => {
      props.pageChanged(newPage)
    },
    [props]
  )

  if (isNaN(totalPages) || totalPages < 1 || props.page > totalPages) {
    return null
  }

  return (
    <>
      <div className='sm:flex hidden justify-center space-x-3 select-none break-all'>
        <span className={classNames('w-32 text-right', { invisible: props.page <= 1 })}>
          <span className='primary-link' onClick={() => pageChanged(props.page - 1)}>
            &lt; Prev
          </span>
        </span>

        <span className='space-x-3'>
          {[...Array(props.page - 1).keys()].map((p) => (
            <span key={p} className='primary-link' onClick={() => pageChanged(p + 1)}>
              {p + 1}
            </span>
          ))}
          {props.size < props.totalCount && (
            <span className='font-bold underline'>{props.page}</span>
          )}
          {[...Array(totalPages - props.page).keys()].map((p) => (
            <span key={p} className='primary-link' onClick={() => pageChanged(props.page + p + 1)}>
              {props.page + p + 1}
            </span>
          ))}
        </span>

        <span className={classNames('w-32 text-left', { invisible: props.page >= totalPages })}>
          <span className='primary-link' onClick={() => pageChanged(props.page + 1)}>
            Next &gt;
          </span>
        </span>
      </div>
      <div className='sm:hidden absolute w-[calc(100%-3rem)]'>
        <div className='flex justify-between space-x-3 mt-4 mb-4 select-none'>
          <span className={props.page > 1 ? '' : 'invisible'}>
            <span className='primary-link' onClick={() => pageChanged(props.page - 1)}>
              &lt; Prev
            </span>
          </span>
          {props.size < props.totalCount && (
            <span className='font-bold underline'>
              {props.page}/{totalPages}
            </span>
          )}
          <span className={props.page < totalPages ? '' : 'invisible'}>
            <span className='primary-link' onClick={() => pageChanged(props.page + 1)}>
              Next &gt;
            </span>
          </span>
        </div>
      </div>
    </>
  )
}

export default Pagination
