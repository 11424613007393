const networkList: any = {
  '0x1': 'Mainnet',
  '0x3': 'Ropsten',
  '0x4': 'Rinkeby',
  '0x5': 'Goerli',
  '0xaa36a7': 'Sepolia',
  '0x2a': 'Kovan',
  '0x7e2': 'Dev',
  '0x3d': 'Classic',
  '0x3f': 'Mordor',
  '0x6': 'Kotti',
  '0xd4': 'Astor'
}

function getNetworkName(chainId: string, omitId: boolean = false): string {
  if (!chainId) {
    return 'unknown'
  }

  if (!chainId.startsWith('0x')) {
    chainId = '0x' + (+chainId).toString(16)
  }

  const networkName = networkList[chainId.toLowerCase()]

  if (networkName) {
    return networkName + (omitId ? '' : ' (' + chainId + ')')
  }

  return chainId
}

export { networkList, getNetworkName }
