function formatNumber(number: number, precision: number = 6) {
  let numberStr = number.toPrecision(precision)
  let parts = numberStr.split('.')
  if (+parts[1] === 0 && parts[1].length > 2) {
    return parts[0] + '.00'
  }

  if (+numberStr > 1000000) {
    return '999999.00'
  }

  let formatted = (+numberStr).toString()

  let splitted = formatted.split('.')
  if (splitted.length > 1 && splitted[1].length < 2) {
    formatted += '0'
  }
  return formatted
}

export { formatNumber }
