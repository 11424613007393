import { Link, useHistory } from 'react-router-dom'
import { userService, web3Service } from '../../../shared/service'
import { useAppContext } from '../../../AppContext'
import { useWeb3Context } from '../../../Web3Context'
import { useEffect, useReducer, useState } from 'react'
import useContract from '../../../shared/hooks/use-contract'
import TransactionTable from '../../../components/TransactionsTable/TransactionTable'
import { User } from '../../../shared/types'
import { formatNumber, getNetworkName } from '../../../shared/utils'
import TokenList from '../../../components/TokenList/TokenList'
import DialogOverlay from '../../../components/DialogOverlay/DialogOverlay'
import pendingTransactionReducer from '../../../shared/reducers/pending-transaction-reducer'

function Profile() {
  const history = useHistory()
  const { user, setUser, accessToken, setAccessToken } = useAppContext()
  const { wallet, setWallet, contract } = useWeb3Context()
  const { tokenBalance, pendingBalance, contractOwner, withdrawPendingBalance } = useContract()
  const [balance, setBalance] = useState<string>('')
  const [state, dispatch] = useReducer(pendingTransactionReducer, {
    message: '',
    loading: false,
    started: false
  })

  useEffect(() => {
    if (wallet && wallet.account) {
      web3Service.getAccountBalance(wallet.account).then((balance) => {
        setBalance(balance)
      })
    }
  }, [wallet])

  const logout = async () => {
    if (!accessToken) {
      return
    }

    let success = await userService.logout(accessToken.accessToken)
    if (success) {
      setAccessToken(null)
      setWallet(null)
      history.push('/account/login')
    }
  }

  const initWallet = async () => {
    try {
      let wallet = await web3Service.connectWallet()
      setWallet(wallet)
    } catch (err) {
      console.error(err)
      return
    }
  }

  const signWallet = async () => {
    if (user && wallet && contract) {
      let result = await web3Service.signUserWallet(user, wallet, contract)
      if (result && accessToken) {
        await userService.updateUser(accessToken.accessToken, {
          ...user,
          signature: result as string
        })
        setUser((prevUser: User) => {
          return { ...prevUser, signature: result as string }
        })
      }
    } else {
      console.error('User, wallet and contract are not initialized')
    }
  }

  const withdrawBalance = async () => {
    if (wallet && contract) {
      dispatch({ type: 'init' })

      let { error } = await withdrawPendingBalance((err, txHash) => {
        if (err) {
          return dispatch({
            type: 'error',
            payload: { error: typeof err === 'string' ? err : err.message }
          })
        }
        dispatch({ type: 'start', payload: { hash: txHash } })
      })

      if (!error) {
        web3Service.getAccountBalance(wallet.account).then((balance) => {
          setBalance(balance)
        })
      } else {
        return dispatch({ type: 'error', payload: { error } })
      }

      dispatch({
        type: 'success',
        payload: {
          message: `<span>Transaction completed, you have successfully withdrawn pending balance of <b>${pendingBalance}</b> ETH to your wallet</span>`
        }
      })
    } else {
      console.error('Wallet and contract are not initialized')
    }
  }

  return (
    <div className='block max-w-2xl 2xl:max-w-4xl mx-auto'>
      <h1 className='page-title text-center px-5 pt-5'>
        Welcome{`${user ? ' ' + user?.firstName : ', dear user'}!`}
      </h1>

      {wallet?.account === contractOwner && (
        <div className='ml-5 sm:mb-0 inline'>
          <Link className='primary-link text-xl' to='/account/admin'>
            Admin panel
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 ml-1 inline align-middle'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z'
              />
            </svg>
          </Link>
        </div>
      )}

      {user && accessToken && (
        <div className='sm:mb-0 pr-5 inline float-right'>
          <span className='primary-link text-xl' onClick={logout}>
            Logout
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 ml-1 inline align-middle'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
              />
            </svg>
          </span>
        </div>
      )}

      {!user && !accessToken && (
        <div className='sm:mb-0 pr-5 inline float-right'>
          <Link className='primary-link text-xl' to='/account/login'>
            Sign in
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 ml-1 inline align-middle'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1'
              />
            </svg>
          </Link>
        </div>
      )}

      <div className='flex flex-col space-y-4 w-full px-5 mt-5'>
        <h2>Your wallet</h2>
        {wallet && wallet.account ? (
          <>
            <span className='break-all sm:break-normal'>
              <b className='block sm:inline'>Account: </b>
              <span className='text-sm sm:text-base'>{wallet.account}</span>
            </span>

            <span>
              <b className='block sm:inline'>Network: </b>
              {getNetworkName(wallet?.chainId, true)}{' '}
              {wallet.connected ? (
                <span className='text-green-600 dark:text-green-400'>(connected)</span>
              ) : (
                <span className='text-red-600 dark:text-red-400'>(disconnected)</span>
              )}
            </span>

            <span className='break-all'>
              <b className='block sm:inline'>Balance: </b>
              {formatNumber(+balance)} ETH
            </span>

            <span className='break-all'>
              <b className='block sm:inline'>Pending balance: </b>
              {formatNumber(+pendingBalance)} ETH
            </span>

            {+pendingBalance > 0 && (
              <button className='primary-button w-full sm:w-48' onClick={withdrawBalance}>
                Withdraw
              </button>
            )}

            {user && !user.signature && (
              <button className='primary-button w-full sm:w-48' onClick={signWallet}>
                Sign wallet
              </button>
            )}
          </>
        ) : (
          <button className='primary-button w-full sm:w-48' onClick={initWallet}>
            Connect wallet
          </button>
        )}
      </div>

      <div className='flex flex-col space-y-4 mt-10'>
        <h2 className='px-5'>Owned tokens</h2>
        {wallet && tokenBalance > 0 ? (
          <>
            <p className='px-5'>
              <span>
                Congratulations! You currently own{' '}
                <span className='text-yellow-600 dark:text-yellow-600 font-bold'>
                  {tokenBalance} MEGA
                </span>{' '}
                token{tokenBalance > 1 ? 's' : ''}.
              </span>
            </p>
            <TokenList key={wallet.account} address={wallet.account} />
          </>
        ) : (
          <p className='px-5'>
            You do not own any tokens at the moment.{' '}
            <Link to='/claim' className='primary-link'>
              Claim your first token now!
            </Link>
          </p>
        )}
      </div>

      <div className='flex flex-col space-y-4 mt-10 pl-5'>
        <h2>Transaction history</h2>
        {wallet ? (
          <TransactionTable key={wallet.account} identifier={wallet.account} clickableId={true} />
        ) : (
          <span>Connect your wallet to see all transactions</span>
        )}
      </div>

      <DialogOverlay
        message={state.message}
        loading={state.loading}
        error={state.error}
        opened={state.started}
        onClose={() => dispatch({ type: 'close' })}
      />
    </div>
  )
}

export default Profile
