import detectEthereumProvider from '@metamask/detect-provider'
import { recoverTypedSignature_v4 } from 'eth-sig-util'
import { toChecksumAddress } from 'ethereumjs-util'
import { User, Wallet } from '../types'
import { web3 } from '../../Web3Context'
import { Contract } from 'web3-eth-contract'

declare const window: Window | any

async function connectWallet(): Promise<Wallet> {
  const provider = await detectEthereumProvider()

  if (!provider || provider !== window.ethereum) {
    throw new Error('MetaMask is not installed')
  }

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
  if (accounts.length === 0) {
    throw new Error('There is no active accounts on your wallet')
  }

  const chainId = await window.ethereum.request({ method: 'eth_chainId' })
  const connected = window.ethereum.isConnected()

  return { account: accounts[0], chainId: chainId, connected: connected }
}

async function getAccountBalance(address: string): Promise<string> {
  const balance = await web3.eth.getBalance(address)
  return web3.utils.fromWei(balance, 'ether')
}

async function signUserWallet(user: User, wallet: Wallet, contract: Contract): Promise<string | null> {
  const msgParams = JSON.stringify({
    domain: {
      // Defining the chain aka Rinkeby testnet, Ethereum Main Net or some others
      chainId: wallet?.chainId,
      // Give a user friendly name to the specific contract you are signing for.
      name: 'Mega NFT',
      // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
      verifyingContract: contract.options.address,
      // Just let's you know the latest version. Definitely make sure the field name is correct.
      version: '1'
    },

    // Defining the message signing data content.
    message: {
      /*
       - Anything you want. Just a JSON Blob that encodes the data you want to send
       - No required fields
       - This is DApp Specific
       - Be as explicit as possible when building out the message schema.
      */
      contents:
        'Hi there from Mega NFT! Sign this message to prove you have access to this wallet and we’ll log you in. ' +
        'This won’t cost you any Ether.\nTo stop malicious using your wallet, here’s a unique message ID: ' +
        user.id,
      signee: {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        wallets: [wallet.account]
      }
    },
    // Refers to the keys of the *types* object below.
    primaryType: 'Data',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ],
      // Refer to PrimaryType
      Data: [{ name: 'signee', type: 'User' }],
      // Not an EIP712Domain definition
      User: [
        { name: 'name', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'wallets', type: 'address[]' }
      ]
    }
  })

  let from = wallet.account

  let params = [from, msgParams]
  let method = 'eth_signTypedData_v4'

  try {
    let signature = await window.ethereum.request({ method, params, from })

    const recovered = recoverTypedSignature_v4({
      data: JSON.parse(msgParams),
      sig: signature
    })

    if (toChecksumAddress(recovered) === toChecksumAddress(from)) {
      return signature
    }
  } catch (err) {
    console.error('Error while signing user wallet:', err)
  }

  return null
}

export const web3Service = {
  connectWallet,
  getAccountBalance,
  signUserWallet
}
