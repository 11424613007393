import { useCallback, useEffect, useState } from 'react'
import { web3 } from '../../Web3Context'

export enum SubscriptionType {
  LOGS = 'logs',
  SYNCING = 'syncing',
  NEW_BLOCK_HEADERS = 'newBlockHeaders',
  PENDING_TRANSACTIONS = 'pendingTransactions'
}

function useMessage(type: SubscriptionType, options: any = {}) {
  const [message, setMessage] = useState()

  const callback = useCallback((error: any, result: any) => {
    if (!error) {
      setMessage(result)
    }
  }, [])

  const subscribe = useCallback(() => {
    if (options === null) {
      return null
    }
    let sub: any
    switch (type) {
      case SubscriptionType.LOGS:
        sub = web3.eth.subscribe('logs', options, callback)
        break
      case SubscriptionType.SYNCING:
        sub = web3.eth.subscribe('syncing', callback)
        break
      case SubscriptionType.NEW_BLOCK_HEADERS:
        sub = web3.eth.subscribe('newBlockHeaders', callback)
        break
      case SubscriptionType.PENDING_TRANSACTIONS:
        sub = web3.eth.subscribe('pendingTransactions', callback)
        break
      default:
        sub = web3.eth.subscribe('logs', {}, callback)
    }

    return sub
  }, [type, options, callback])

  useEffect(() => {
    const sub = subscribe()
    return () => {
      sub?.unsubscribe()
    }
  }, [subscribe])

  return message
}

export default useMessage
