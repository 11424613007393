import { useWeb3Context } from '../../Web3Context'
import { Redirect } from 'react-router-dom'
import { getNetworkName } from '../../shared/utils'

const { REACT_APP_NETWORK_ID } = process.env

function NetworkNotSupported() {
  const { wallet, contract } = useWeb3Context()

  if (contract) {
    return <Redirect to='/account' />
  }

  return (
    <div className='page-container text-center space-y-10'>
      <h1>Network not supported</h1>
      <div className='well'>
        <p className='mb-10'>
          The currently connected network <b>{getNetworkName(wallet?.chainId)}</b> is not supported by
          application.
        </p>
        <p className='text-yellow-600 dark:text-yellow-500'>
          Please switch to <b>{getNetworkName(REACT_APP_NETWORK_ID || '0')}</b> in order to
          continue using this site.
        </p>
      </div>
    </div>
  )
}

export default NetworkNotSupported
