const rarityList = ['Common', 'Rare', 'Epic', 'Legendary']

function rarityName(rarity: number | undefined): string {
  if (!rarity || rarity < 1) {
    return ''
  }
  return rarityList[rarity - 1]
}

function rarityNumber(rarity: string | undefined): number {
  return rarityList.findIndex(r => r.toLowerCase() === rarity?.toLowerCase()) + 1
}

export { rarityName, rarityNumber, rarityList }
