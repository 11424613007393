import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className='page-container text-center space-y-10'>
      <h1>404 Page not found</h1>
      <p className='text-xl'>The page you are looking for does not exist!</p>
      <p className='primary-link text-xl'>
        <Link to='/'>
          &lt; Return to homepage
        </Link>
      </p>
    </div>
  )
}

export default NotFound
