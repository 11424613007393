function About() {
  return (
    <div className='page-container'>
      <article className='prose prose-md sm:prose-lg mx-auto'>
        <h1 className='text-center'>Who are we?</h1>
        <p className='text-justify sm:text-left'>
          We are a collective of scientists, developers, artists, and blockchain engineers.
        </p>
        <p className='text-justify sm:text-left'>
          We wanted to build a very cool project that would demonstrate our passion for the NFT
          technology.
        </p>
        <p className='text-justify sm:text-left'>
          While we have decided to stay anonymous for now, we will reveal our identity some time in
          the future.
        </p>
        <br/>
        <q className='float-right'>Luka M. - founder of Mega NFT</q>
      </article>
    </div>
  )
}

export default About
