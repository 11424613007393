import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { rarityList } from '../../shared/utils'

type TokenFilterProps = {
  filters?: Filter[]
  filtersQuery?: string
  filtersChanged?: (newFilters: string, newFiltersArray: Filter[]) => void
}

export type Filter = {
  name: string
  value?: string | boolean
}

const initialFilters = rarityList
  .map((name) => ({ name, value: false }))
  .reverse()
  .concat([
    { name: 'For sale', value: false },
    { name: 'Open to offers', value: false },
    { name: 'Not for sale', value: false }
  ])

function serializeFilters(filters: Filter[]): string {
  return filters
    .filter((f) => f.value)
    .map((f) => f.name.toLowerCase())
    .join(',')
}

function deserializeFilters(filters: string): Filter[] {
  if (!filters) {
    return [...initialFilters]
  }
  const selectedFilters = filters.split(',').map((f) => f[0].toUpperCase() + f.slice(1))
  return initialFilters.map((f) => ({
    name: f.name,
    value: selectedFilters.some((sf) => sf === f.name)
  }))
}

function TokenFilter(props: TokenFilterProps) {
  const selectRef = useRef<HTMLSelectElement>(null)
  const [filters, setFilters] = useState<Filter[]>(
    props.filters ? props.filters : deserializeFilters(props.filtersQuery || '')
  )

  useEffect(() => {
    if (!selectRef.current) {
      return
    }

    selectRef.current.disabled = !filters.some((f) => !f.value)
  }, [filters])

  const removeFilter = (filter: Filter) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.map((f) =>
        f.name === filter.name ? { name: f.name, value: false } : f
      )
      props.filtersChanged?.(serializeFilters(newFilters), newFilters)
      return newFilters
    })
  }

  const addFilter = (value: string) => {
    if (selectRef.current) {
      selectRef.current.value = ''
    }
    setFilters((prevFilters) => {
      const newFilters = prevFilters.map((f) =>
        f.name === value ? { name: f.name, value: true } : f
      )
      props.filtersChanged?.(serializeFilters(newFilters), newFilters)
      return newFilters
    })
  }

  return (
    <div className='xl:ml-6 py-4'>
      <div className='flex flex-wrap items-center md:space-x-2'>
        <select
          ref={selectRef}
          className='input-field focus-ring w-screen md:w-32 mb-2 md:mb-0'
          defaultValue={''}
          onChange={(e) => addFilter(e.target.value)}
        >
          <option value='' disabled hidden>
            Filter by
          </option>
          {filters
            .filter((f) => !f.value)
            .map((filter) => (
              <option key={filter.name} value={filter.name}>
                {filter.name}
              </option>
            ))}
        </select>

        {filters
          .filter((f) => f.value)
          .map((filter) => (
            <div
              key={filter.name}
              className={classNames('m-1 md:m-0 border rounded-full py-1 px-4', {
                [filter.name.toLowerCase()]: true
              })}
            >
              <span className='pr-1'>{filter.name}</span>
              <svg
                onClick={() => removeFilter(filter)}
                xmlns='http://www.w3.org/2000/svg'
                className='h-4 w-4 inline cursor-pointer'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
          ))}
      </div>
    </div>
  )
}

export default TokenFilter
